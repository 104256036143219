export const menus: {
  title: string;
  link: string;
  forFooter?: boolean;
  notReady?: boolean;
  children?: {
    title: string;
    link: string;
  }[];
}[] = [
  {
    title: 'Home',
    link: '/',
  },
  {
    title: 'Land',
    link: 'https://flashprotocol.xyz/nfts/collection?id=4',
    forFooter: true,
  },
  {
    title: 'eSports',
    link: '/e-sports',
    forFooter: true,
  },
  {
    title: 'Airdrop',
    link: '/airdrop',
    forFooter: true,
    notReady: true,
  },
  {
    title: 'Docs',
    link: '',
    forFooter: true,
    children: [
      {
        title: 'OnePage',
        link: 'https://drive.google.com/file/d/1uIRg7xS9V8BbubWZERAGMkeqCis1-1ul/view?usp=drive_link',
      },
      {
        title: 'Deck',
        link: 'https://docsend.com/view/5rrs5cg8y3gjrgcu',
      },
    ],
  },
];
