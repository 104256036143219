import { FC, ReactNode, useState } from 'react';
import { passportModel } from '../models/passport.model';
import styles from './avatar.module.scss';
import { useComputed, useModel } from 'foca';
import { ConnectButton } from '../components/connect-button';
import arrowUpIcon from '@assets/images/arrow-up.svg';
import copyIcon from '@assets/images/menu-copy.svg';
import logoutIcon from '@assets/images/menu-logout.svg';
import dashboardIcon from '@assets/images/menu-dashboard.svg';
import recordIcon from '@assets/images/menu-record.svg';
import settingIcon from '@assets/images/menu-setting.svg';
import { Dropdown } from '@ui/dropdown';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import { config } from '@config';
import { useWalletAccount, useWalletNetwork } from '@hooks/use-wallet';
import { walletExtensionModel } from '@models/wallet-extension.model';
import { Link, useNavigate } from 'react-router-dom';
import { networkMeta } from '@constants/wallet-network';
import { addressToDot } from '@utils/address-to-dot';
import { Button } from '@ui/button';

export const Avatar: FC = () => {
  const network = useWalletNetwork();
  const address = useWalletAccount();
  const tokenAvailable = useComputed(passportModel.tokenAvailable, address);
  const [opened, setOpened] = useState(false);
  const navigate = useNavigate();
  const connecting = useModel(walletExtensionModel, (state) => state.connecting);

  const handleLogout = () => {
    passportModel.resetToken();
    walletExtensionModel.reset();
    toast.success('Wallet disconnected!');
  };

  const handleCopy = () => {
    copy(address!);
    toast.success('Address is copied');
  };

  if (tokenAvailable && network) {
    const $overlay: ReactNode = (
      <ul>
        <li onClick={handleCopy}>
          <img src={copyIcon} />
          <span>Copy Address</span>
        </li>
        <li onClick={() => navigate('/dashboard')}>
          <img src={dashboardIcon} />
          <span>Dashboard</span>
        </li>
        <li onClick={() => navigate('/dashboard/records')}>
          <img src={recordIcon} />
          <span>Records</span>
        </li>
        <li onClick={() => navigate('/dashboard/settings')}>
          <img src={settingIcon} />
          <span>Settings</span>
        </li>
        <li className={styles.logout} onClick={handleLogout}>
          <img src={logoutIcon} />
          <span>Log out</span>
        </li>
      </ul>
    );

    return (
      <div className={styles.wrapper}>
        <Link className={styles.box} to="/dashboard">
          <img src={dashboardIcon} />
        </Link>
        <Dropdown onVisibleChange={setOpened} overlay={$overlay}>
          <div className={`${styles.box} ${opened ? styles.opened : ''}`}>
            <img src={networkMeta[network].icon} className={styles.avatar} />
            {addressToDot(address)}
            <img src={arrowUpIcon} className={styles.arrow} />
          </div>
        </Dropdown>
        <Button
          size="default"
          type="primary"
          className={styles.setting_button}
          onClick={() => navigate('/dashboard/settings')}
        >
          <img src={settingIcon} />
        </Button>
      </div>
    );
  }

  if (!config.showSignInButton) return null;

  return (
    <ConnectButton>
      <Button size="default" type="primary" loading={connecting}>
        {connecting ? 'Connecting' : 'Sign In'}
      </Button>
    </ConnectButton>
  );
};
