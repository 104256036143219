import { defineModel } from 'foca';
import { http } from '../services/http';
import { WalletNetwork } from '@constants/wallet-network';

export const passportModel = defineModel('passport', {
  initialState: {
    token: '',
    expires_at: 0,
    address: '',
    network: WalletNetwork.bnbSmartChain,
    provider: '',
  },
  reducers: {
    resetToken() {
      return this.initialState;
    },
  },
  computed: {
    tokenAvailable(address: string | undefined) {
      return !!this.state.token && this.state.address === address;
    },
  },
  methods: {
    async getNonce(walletAddress: string) {
      const result = await http.post<{ nonce: string }>('/client/passport/nonce', {
        walletAddress,
      });
      return result.nonce;
    },
    async auth(data: {
      walletAddress: string;
      signature: string;
      network: WalletNetwork;
      publicKey: string;
    }) {
      const result = await http.post<{ token: string; expires_at: number }>(
        '/client/passport/auth',
        data,
      );
      this.setState({
        ...result,
        address: data.walletAddress,
      });
    },
  },
  persist: {
    version: 2,
    dump(state) {
      return state;
    },
    load(dumpData) {
      if (dumpData.expires_at < Date.now()) {
        return this.initialState;
      }
      return dumpData;
    },
  },
});
