import { http } from '@libs/http';
import { defineModel } from 'foca';

export interface GameItem {
  id: number;
  name: string;
  logo: string;
  assets_name: string;
  rates: { [tokenCoinId: string]: string };
  assets_balance: number;
  description: string;
  gallery: string[];
  community: { platform: string; link: string }[];
  game_type: string | null;
  developer: string | null;
  status: string | null;
}

const initialState: {
  all: PaginationResult<GameItem>;
  mine: PaginationResult<GameItem>;
  games: Record<string, GameItem>;
} = {
  all: {
    page: 1,
    total: 0,
    limit: 10,
    result: [],
  },
  mine: {
    page: 1,
    total: 0,
    limit: 10,
    result: [],
  },
  games: {},
};

export const gameModel = defineModel('game', {
  initialState,
  reducers: {
    append(state, games: GameItem[]) {
      games.forEach((game) => {
        state.games[game.id] = game;
      });
    },
  },
  methods: {
    async queryAllList(search: string, page: number, limit: number) {
      const result = await http.get<PaginationResult<GameItem>>('/client/games', {
        params: {
          search,
          page,
          limit,
        },
      });
      this.setState({ all: result });
      this.append(result.result);
    },
    async queryMineList(search: string, page: number, limit: number) {
      const result = await http.get<PaginationResult<GameItem>>('/client/games/mine', {
        params: {
          search,
          page,
          limit,
        },
      });
      this.setState({ mine: result });
      this.append(result.result);
    },
    async transfer(data: {
      gameId: number;
      tokenCoinId: number;
      type: 'in' | 'out';
      gameAmount: string;
    }) {
      await http.post(`/client/games/transfer/${data.type}`, data);
    },
    async getGameDetail(gameId: number) {
      const result = await http.get<GameItem>(`/client/games/${gameId}`);
      this.append([result]);
    },
  },
});
