import { FC, useState } from 'react';
import styles from './withdraw.module.scss';
import { Button } from '@ui/button';
import { CoinInput } from '@components/coin-input';
import { useTokenCoins } from '@hooks/use-token-coin';
import { useDepositBalances } from '@hooks/use-deposit-balance';
import { balanceModel } from '@models/balance.model';
import { useLoading } from 'foca';
import { DialogLoading } from '@components/dialog-loading';
import { Dialog } from '@ui/dialog';
import icon from '@assets/images/dialog-ok.svg';
import Decimal from 'decimal.js';

export const Withdraw: FC = () => {
  const { coins } = useTokenCoins();
  const { balances } = useDepositBalances();
  const [amount, setAmount] = useState('');
  const loading = useLoading(balanceModel.withdraw);
  const [showDialog, setShowDialog] = useState(false);

  const firstCoin = coins[0];
  const firstBalance = balances[0];

  const handleSubmit = async () => {
    await balanceModel.withdraw(firstCoin!.id, amount);
    setShowDialog(true);
    balanceModel.queryList();
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    setAmount('');
  };

  const insufficient =
    !!firstBalance && !!amount && new Decimal(amount).greaterThan(firstBalance.amount);

  if (!firstCoin) return null;

  return (
    <>
      <div className={styles.wrapper}>
        <p className={styles.desc}>Withdraw platform balance to your wallet</p>
        <CoinInput
          value={amount}
          onChange={setAmount}
          className={styles.input}
          logo={firstCoin.logo}
          symbol={firstCoin.symbol}
          balance={firstBalance?.amount}
          balancePrefix="Available: "
        />
        <Button
          block
          size="large"
          className={styles.button}
          disabled={!amount || insufficient}
          onClick={handleSubmit}
        >
          {insufficient ? 'Insufficient balance' : 'Withdraw'}
        </Button>
      </div>
      <DialogLoading loading={loading} description={`Withdrawing ${amount} ${firstCoin.symbol}`} />
      <Dialog visible={showDialog} className={styles.dialog}>
        <img src={icon} />
        <p className={styles.title}>All Done!</p>
        <p className={styles.desc}>
          You have withdraw {amount} {firstCoin.symbol}
        </p>
        <Button className={styles.button} block onClick={handleCloseDialog}>
          Ok
        </Button>
      </Dialog>
    </>
  );
};
