import { useLoading, useModel } from 'foca';
import { FC, useEffect, useState } from 'react';
import { Land } from './land';
import styles from './lands.module.scss';
import { flashModel } from '@models/flash.model';
import { Pagination } from '@ui/pagination';
import { Empty } from '@ui/empty';
import { Loading } from '@ui/loading';

export const Lands: FC = () => {
  const [page, setPage] = useState(1);
  const { nfts, connected } = useModel(flashModel);
  const loading = useLoading(flashModel.getNfts);
  const limit = 6;
  const startIndex = (page - 1) * limit;
  const endIndex = page * limit;

  useEffect(() => {
    setPage(1);
    flashModel.getNfts();
  }, [connected]);

  const handlePageChange = (nextPage: number) => {
    setPage(nextPage);
  };

  return (
    <>
      <Loading spinning={loading && !nfts.length}>
        <div className={`${styles.wrapper} ${nfts.length ? '' : styles.empty_wrapper}`}>
          {!nfts.length && <Empty />}
          {nfts.slice(startIndex, endIndex).map((land, index) => (
            <Land key={land.item_id} land={land} index={startIndex + index} />
          ))}
        </div>
      </Loading>
      <Pagination
        page={page}
        pageSize={limit}
        totalRows={nfts.length}
        onChange={handlePageChange}
        className={styles.pagination}
      />
    </>
  );
};
