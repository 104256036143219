import { SectionTitle } from '@components/section-title';
import { FC } from 'react';
import styles from './metaverse.module.scss';
import gamePadIcon from '@assets/images/game-pad.svg';

export const Metaverse: FC = () => {
  return (
    <>
      <SectionTitle
        top={0}
        bottom={20}
        mobileBottom={32}
        mobileTop={0}
        mobileTitle={
          <p>
            What is
            <br />
            elfin metaverse
          </p>
        }
      >
        What is elfin metaverse
      </SectionTitle>
      <p className={styles.title}>
        An all-in-one eSports gaming platform and open-world metaverse built on Elfin Lands*
      </p>
      <div className={styles.cards}>
        <div className={styles.card}>
          <p>For</p>
          <p>PLAYERS</p>
          <p>Daily Battles | Seasonal Tournaments | Social Events</p>
        </div>
        <div className={styles.card}>
          <p>For</p>
          <p>ELFIN LANDS OWNERS</p>
          <p>Exclusive NFTs | Economic Rewards | Functional & Cosmetic Customization</p>
        </div>
        <div className={styles.card}>
          <p>For</p>
          <p>WEB3 COMMUNITIES</p>
          <p>Bluechip Web3 Communites | Game Guilds | DAOs</p>
        </div>
        <div className={styles.card}>
          <p>For</p>
          <p>GAME DEVELOPERS / STUDIOS / PUBLISHERS</p>
          <p>Digital IP Issuance | New Games Fair Launch & Mint | Initial Games Offering</p>
        </div>
      </div>
      <img className={styles.game_pad} src={gamePadIcon} />
    </>
  );
};
