import { FC, MouseEvent, useState } from 'react';
import styles from './game-detail.module.scss';
import transferInIcon from '@assets/images/transfer-in.svg';
import transferOutIcon from '@assets/images/transfer-out.svg';
import { gameModel } from '@models/game.model';
import { DialogTransfer } from './dialog-transfer';
import { useNavigate } from 'react-router-dom';
import { useModel } from 'foca';

interface OwnProps {
  gameId: number;
}

export const GameDetail: FC<OwnProps> = ({ gameId }) => {
  const game = useModel(gameModel, (state) => state.games[gameId]);
  const [dialog, setDialog] = useState<'in' | 'out' | 'close'>('close');
  const navigate = useNavigate();

  const handleGoToDetail = () => {
    navigate(`/game/${gameId}`);
  };

  const handleTransferIn = (e: MouseEvent) => {
    e.stopPropagation();
    setDialog('in');
  };

  const handleTransferOut = (e: MouseEvent) => {
    e.stopPropagation();
    setDialog('out');
  };

  if (!game) return null;

  return (
    <>
      <div className={styles.box} onClick={handleGoToDetail}>
        <div className={styles.logo_wrapper}>
          <img className={styles.logo} src={game.logo} />
          <div className={styles.buttons}>
            <img src={transferInIcon} onClick={handleTransferIn} />
            <img src={transferOutIcon} onClick={handleTransferOut} />
          </div>
        </div>
        <p className={styles.game_name}>{game.name}</p>
        <div className={styles.assets_wrapper}>
          <p className={styles.assets_amount}>{game.assets_balance}</p>
          <p className={styles.assets_name}>{game.assets_name}</p>
        </div>
      </div>
      {dialog !== 'close' && (
        <DialogTransfer forceGame={game} defaultType={dialog} onClose={() => setDialog('close')} />
      )}
    </>
  );
};
