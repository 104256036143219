import { DepositOpts, WalletBase } from './wallet-base';
import { WalletNetwork } from '@constants/wallet-network';
import icon from '@assets/images/wallet-onekey.svg';
import { OnekeyBTCProvider } from './onekey-btc-provider';
import { OnekeyETHProvider } from './onekey-eth-provider';
import { OnekeyProvider } from './onekey-provider';

export class OnekeyWallet extends WalletBase {
  protected readonly btcProvider!: OnekeyProvider;
  protected readonly ethProvider!: OnekeyProvider;

  constructor() {
    super();
    if (this.getMeta().installed) {
      this.btcProvider = new OnekeyBTCProvider();
      this.ethProvider = new OnekeyETHProvider();
    }
  }

  override getSupportedChains(): WalletNetwork[] {
    return [WalletNetwork.bitcoin, WalletNetwork.bnbSmartChain];
  }

  override getMeta(): { icon: string; name: string; installed: boolean } {
    return {
      icon: icon,
      name: 'OneKey Wallet',
      installed: !!window.$onekey,
    };
  }

  protected get provider() {
    return this.network === WalletNetwork.bitcoin ? this.btcProvider : this.ethProvider;
  }

  override async connect(network: WalletNetwork) {
    this.setNetwork(network);
    return this.provider.connect();
  }

  signMessage(message: string, address: string) {
    return this.provider.signMessage(message, address);
  }

  override getPublicKey(): Promise<string> {
    return this.provider.getPublicKey();
  }

  override async isConnected(): Promise<boolean> {
    switch (this.network) {
      case WalletNetwork.bnbSmartChain:
        return this.ethProvider.isConnected();
      case WalletNetwork.bitcoin:
        return this.btcProvider.isConnected();
      default:
        return false;
    }
  }

  getAccount(): Promise<string | undefined> {
    return this.provider.getAccount();
  }

  override async getBalance(tokenCoinAddress: string): Promise<string> {
    return this.provider.getBalance(tokenCoinAddress);
  }

  override enableTransaction(): boolean {
    return this.provider.enableTransaction();
  }

  override deposit(opts: DepositOpts): Promise<string> {
    return this.provider.deposit(opts);
  }
}
