import { FC } from 'react';
import styles from './profile.module.scss';
import avatarIcon from '@assets/images/avatar.svg';
import checkIcon from '@assets/images/check.svg';
import { useDepositBalances } from '@hooks/use-deposit-balance';
import { useWalletAccount } from '@hooks/use-wallet';

export const Profile: FC = () => {
  const address = useWalletAccount() || '';
  const { balances } = useDepositBalances();

  return (
    <div className={styles.wrapper}>
      <img src={avatarIcon} className={styles.avatar} />
      <div className={styles.profile}>
        <p>{address.replace(/(\w{6}).+(\w{4})$/, '$1....$2')}</p>
        <div className={styles.tag}>
          Connected
          <img src={checkIcon} />
        </div>
      </div>
      {balances.map((balance) => {
        return (
          <div className={styles.balance} key={balance.id}>
            <p>Platform balance({balance.token_coin?.symbol})</p>
            <p className={styles.numbers}>{balance.amount}</p>
          </div>
        );
      })}
    </div>
  );
};
