import bscIcon from '@assets/images/token-coin-bsc.png';
import btcIcon from '@assets/images/token-coin-btc.png';
import flashIcon from '@assets/images/token-coin-flash.svg';

export enum WalletNetwork {
  bnbSmartChain = 1,
  bitcoin,
  flash,
}

export const networkMeta: Record<WalletNetwork, { label: string; icon: string }> = {
  [WalletNetwork.bnbSmartChain]: {
    label: 'BNB Chain',
    icon: bscIcon,
  },
  [WalletNetwork.bitcoin]: {
    label: 'Bitcoin',
    icon: btcIcon,
  },
  [WalletNetwork.flash]: {
    label: 'Flash',
    icon: flashIcon,
  },
};
