import type { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import loadingIcon from '@assets/images/loading.svg';

interface OwnProps {
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  /**
   * Full width. Default `false`
   */
  block?: boolean;
  /**
   * Defaults `primary`
   */
  type?: 'primary' | 'border';
  /**
   * Defaults `large`
   */
  size?: 'large' | 'default' | 'small';
  children: ReactNode;
  onClick?: () => void;
  href?: string;
  hrefTarget?: '_self' | '_blank';
}

export const Button: FC<OwnProps> = ({
  disabled = false,
  children,
  loading = false,
  block = false,
  className = '',
  size = 'large',
  type = 'primary',
  onClick,
  href,
  hrefTarget,
}) => {
  const classNames = `${styles.button} ${block ? styles.full_width : ''} ${
    disabled ? styles.disabled : ''
  } ${loading ? styles.loading : ''} ${styles[`size_${size}`]} ${
    type === 'primary' ? styles.type_primary : styles.type_border
  } ${className}`;
  const $loading = loading && <img src={loadingIcon} className={styles.loading_icon} />;

  if (href) {
    return (
      <a
        href={href}
        target={hrefTarget}
        onClick={loading || disabled ? (e) => e.preventDefault() : onClick}
        className={classNames}
      >
        {$loading}
        {children}
      </a>
    );
  }

  return (
    <button onClick={loading || disabled ? undefined : onClick} className={classNames}>
      {$loading}
      {children}
    </button>
  );
};
