import { FC, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import backIcon from '@assets/images/direction-left.svg';
import { useModel } from 'foca';
import { gameModel } from '@models/game.model';
import { Button } from '@ui/button';
import { Gallery } from './components/gallery';
import { ConnectButton } from '@components/connect-button';
import { config } from '@config';
import { passportModel } from '@models/passport.model';
import { walletExtensionModel } from '@models/wallet-extension.model';

export const GameDetail: FC = () => {
  const gameId = Number(useParams()['id']);
  const game = useModel(gameModel, (state) => state.games[gameId]);
  const token = useModel(passportModel, (state) => state.token);
  const connecting = useModel(walletExtensionModel, (state) => state.connecting);

  useEffect(() => {
    gameModel.getGameDetail(gameId);
  }, [gameId]);

  if (!game) return null;

  return (
    <div className={styles.wrapper}>
      <Link to="/dashboard" className={styles.back}>
        <img src={backIcon} />
        Back
      </Link>
      <div className={styles.body}>
        <div className={styles.left}>
          <Gallery pictures={game.gallery} />
        </div>
        <div className={styles.right}>
          <p className={styles.title}>{game.name}</p>
          <div className={styles.gap}></div>
          <p className={styles.description}>{game.description}</p>
          <div className={styles.gap}></div>
          <ConnectButton>
            <Button
              block
              href={`${config.api_host}/client/games/${game.id}/play?token=${token}`}
              hrefTarget="_blank"
              loading={connecting}
            >
              {connecting ? 'Connecting' : 'Play'}
            </Button>
          </ConnectButton>
          <div className={styles.gap}></div>
          <div className={`${styles.row} ${styles.community}`}>
            <span>Community</span>
            <div></div>
          </div>
          <div className={styles.gap}></div>
          <div className={styles.row}>
            <span>Game Type</span>
            <span>{game.game_type}</span>
          </div>
          <div className={styles.row}>
            <span>Developer</span>
            <span>{game.developer}</span>
          </div>
          <div className={styles.row}>
            <span>Status</span>
            <span>{game.status}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
