import { AllGame } from './components/all-game';
import { Deposit } from './components/deposit';
import { DialogTransfer } from './components/dialog-transfer';
import { MyGame } from './components/my-game';
import { Profile } from './components/profile';
import { Withdraw } from './components/withdraw';
import { Button } from '@ui/button';
import { Input } from '@ui/input';
import { Tab } from '@ui/tab';
import { FC, useState } from 'react';
import styles from './index.module.scss';
import { HashRate } from './components/hashrate';
import { Lands } from './components/lands';

export const DashboardAssets: FC = () => {
  const [swap, setSwap] = useState(false);

  return (
    <>
      <div className={styles.left}>
        <div className={`${styles.area} ${styles.land_area}`}>
          <p className={styles.title}>Land Assets</p>
          <HashRate />
          <Lands />
        </div>
        <div className={`${styles.area} ${styles.game_area}`}>
          <div className={styles.header}>
            <p className={styles.title}>Game Assets</p>
            <Input className={styles.search} search />
            <Button size="default" onClick={() => setSwap(true)}>
              Transfer
            </Button>
          </div>
          <Tab
            headerClassName={styles.tabs}
            items={[
              {
                label: 'All Games',
                panel: () => <AllGame />,
              },
              {
                label: 'My Games',
                panel: () => <MyGame />,
              },
            ]}
          />
        </div>
      </div>
      <div className={styles.right}>
        <div className={`${styles.area} ${styles.profile_area}`}>
          <Profile />
        </div>
        <div className={`${styles.area} ${styles.trade_area}`}>
          <Tab
            type="card"
            items={[
              {
                label: 'Deposit',
                panel: () => <Deposit />,
              },
              {
                label: 'Withdraw',
                panel: () => <Withdraw />,
              },
            ]}
          />
        </div>
      </div>
      {swap && <DialogTransfer defaultType="swap" onClose={() => setSwap(false)} />}
    </>
  );
};
