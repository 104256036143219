import { FC } from 'react';
import styles from './index.module.scss';
import { Partner } from './components/partner';
import { FirstPage } from './components/first-page';
import { Community } from './components/community';
import { Metaverse } from './components/metaverse';
import { Team } from './components/team';
import { Roadmap } from './components/roadmap';

export const Home: FC = () => {
  return (
    <div className={styles.wrapper}>
      <FirstPage />
      <Metaverse />
      <Partner />
      <Team />
      <Roadmap />
      <Community />
    </div>
  );
};
