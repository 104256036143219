import production, { Config } from './production';

const integration: Config = {
  ...production,
  api_host: 'http://34.235.165.224:7002',
  wallet_project_id: '93733c2b5aaec503a08f0d161f92449e',
  is_mainnet: false,
  bsc_chain_id: '0x' + (97).toString(16),
};

export default integration;
