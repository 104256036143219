import { WalletNetwork } from '@constants/wallet-network';
import { DepositOpts, WalletBase } from './wallet-base';
import icon from '@assets/images/wallet-metamask.svg';

import Web3 from 'web3';
import {
  etchSwitchChain,
  ethDeposit,
  ethGetSelectedAddress,
  ethGetTokenCoinBalance,
  ethPersonalSignMessage,
} from './common';

export class MetamaskWallet extends WalletBase {
  protected readonly provider: ONEKEY_ETH;
  protected web3!: Web3;

  constructor() {
    super();
    this.provider = window.ethereum;
    this.web3 = new Web3(this.provider);
  }

  override getSupportedChains(): WalletNetwork[] {
    return [WalletNetwork.bnbSmartChain];
  }

  override getMeta(): { icon: string; name: string; installed: boolean } {
    return {
      icon,
      name: 'MetaMask',
      installed: window.ethereum && window.ethereum.isMetaMask,
    };
  }

  override async connect(): Promise<string> {
    await etchSwitchChain(this.provider);
    const addresses = await this.provider.request({ method: 'eth_requestAccounts' });
    return addresses[0]!;
  }

  override async signMessage(message: string, address: string): Promise<string> {
    return ethPersonalSignMessage(this.provider, address, message);
  }

  override async isConnected(): Promise<boolean> {
    return this.provider.isConnected();
  }

  override async getAccount() {
    return ethGetSelectedAddress(this.provider);
  }

  override async getBalance(tokenCoinAddress: string): Promise<string> {
    const address = await this.getAccount();
    return ethGetTokenCoinBalance(this.web3, tokenCoinAddress, address!);
  }

  override enableTransaction(): boolean {
    return true;
  }

  override async deposit(opts: DepositOpts): Promise<string> {
    return ethDeposit(this.web3, opts);
  }
}
