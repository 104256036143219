import type { RouteObject } from 'react-router-dom';
import { App } from '../app';
import { Home } from '../pages/home';
import { Dashboard } from '../pages/dashboard';
import { GameDetail } from '@pages/game-detail';
import { ESports } from '@pages/e-sports';
import { DashboardAssets } from '@pages/dashboard-assets';
import { DashboardRecords } from '@pages/dashboard-records';
import { DashboardSetting } from '@pages/dashboard-setting';

export const routers: RouteObject[] = [
  {
    element: <App />,
    path: '/',
    children: [
      { path: '/', element: <Home /> },
      {
        path: '/dashboard',
        element: <Dashboard />,
        children: [
          { path: '/dashboard', element: <DashboardAssets /> },
          { path: '/dashboard/records', element: <DashboardRecords /> },
          { path: '/dashboard/settings', element: <DashboardSetting /> },
        ],
      },
      { path: '/game/:id', element: <GameDetail /> },
      { path: '/e-sports', element: <ESports /> },
    ],
  },
];
