import { Button } from '@ui/button';
import { FC, useEffect } from 'react';
import styles from './hashrate.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { flashModel } from '@models/flash.model';
import { useLoading, useModel } from 'foca';

export const HashRate: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const connecting = useLoading(flashModel.auth);
  const checking = useLoading(flashModel.checkConnected);
  const connected = useModel(flashModel, (state) => state.connected);

  useEffect(() => {
    flashModel.checkConnected();
  }, []);

  useEffect(() => {
    const matched = location.search.match(/code=([a-z0-9]+)/i) || [];
    const code = matched[1];
    if (code) {
      flashModel.auth(code).finally(() => {
        navigate('/dashboard');
      });
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <p className={styles.label}>My Hashrate</p>
      <p className={styles.value}>0</p>
      {connecting ? (
        <Button className={styles.button} size="default" type="primary" loading>
          Connecting
        </Button>
      ) : (
        <Button
          className={styles.button}
          size="default"
          type="primary"
          href={`https://flash-git-angel-hub-qingzhu123.vercel.app/oauth/authorize?response_type=code&client_id=1aff0cc17477139678da419216b75981753159ee79732d50c12e7b7d351f4476&redirect_uri=${encodeURIComponent(
            'http://elfin.website.s3-website-us-east-1.amazonaws.com',
          )}`}
          disabled={connected || checking}
        >
          Related Flash Account
        </Button>
      )}
    </div>
  );
};
