import { Dialog } from '@ui/dialog';
import { FC, useState } from 'react';
import icon from '@assets/images/dialog-error.svg';
import { Button } from '@ui/button';
import styles from './index.module.scss';

interface OwnProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onConfirm?(): any;
  okText?: string;
  cancelText?: string;
  hasCancelButton?: boolean;
  onCancel?(): void;
  title?: string;
  description?: string;
  className?: string;
}

export const Confirm: FC<OwnProps> = ({
  onConfirm,
  okText = 'OK',
  cancelText = 'Cancel',
  hasCancelButton: hasCancel = true,
  onCancel,
  title,
  description,
  className = '',
}) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const result = onConfirm?.();
    if (result instanceof Promise) {
      setLoading(true);
      try {
        await result;
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog visible className={`${styles.dialog} ${className}`}>
      <img src={icon} />
      <p className={styles.title}>{title}</p>
      <p className={styles.desc}>{description}</p>
      <div className={styles.buttons}>
        {hasCancel && (
          <Button block type="border" onClick={onCancel}>
            {cancelText}
          </Button>
        )}
        <Button block onClick={handleConfirm} loading={loading}>
          {okText}
        </Button>
      </div>
    </Dialog>
  );
};
