import { FC, MouseEvent, useState } from 'react';
import styles from './header.module.scss';
import listIcon from '../assets/images/list.svg';
import closeIcon from '../assets/images/close.svg';
import ModernDrawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import logoIcon from '../assets/images/logo.svg';
import { menus } from '../routers/menu';
import { Link, useLocation } from 'react-router-dom';
import { Avatar } from './avatar';
import NotReadyTooltip from './components/not-ready-tooltip';
import { Menu } from './menu';

export const Header: FC = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const location = useLocation();

  const handleDrawerClick = (event: MouseEvent) => {
    if (event.target && (event.target as HTMLElement).nodeName === 'A') {
      setOpenDrawer(false);
    }
  };

  return (
    <>
      <header className={styles.wrapper}>
        <div className={styles.side}>
          <div className={styles.left}>
            <img className={styles.logo} src={logoIcon} />
          </div>
          <Avatar />
          <img
            src={listIcon}
            className={`${styles.mobile} ${styles.list_icon}`}
            onClick={() => setOpenDrawer(true)}
          />
        </div>
        <div className={`${styles.desktop} ${styles.center}`}>
          <Menu className={styles.menus} activeClassName={styles.active} />
        </div>
      </header>
      <ModernDrawer
        duration={0}
        lockBackgroundScroll
        open={openDrawer}
        direction="right"
        className={styles.drawer}
      >
        <div className={styles.drawer_inner} onClick={handleDrawerClick}>
          <div className={styles.header}>
            <div className={styles.left}>
              <img className={styles.logo} src={logoIcon} />
            </div>
            <Avatar />
            <img className={styles.close} src={closeIcon} onClick={() => setOpenDrawer(false)} />
          </div>
          <ul className={styles.menus}>
            {menus.map((menu) => {
              if (menu.notReady) {
                return (
                  <NotReadyTooltip key={menu.title}>
                    <li>{menu.title}</li>
                  </NotReadyTooltip>
                );
              }

              if (menu.children?.length) {
                return (
                  <li key={menu.title} className={styles.with_children}>
                    <p>{menu.title}</p>
                    <div>
                      {menu.children.map((child) => {
                        return (
                          <Link
                            key={child.link}
                            to={child.link}
                            target={child.link.indexOf('http') === 0 ? '_blank' : '_self'}
                          >
                            {child.title}
                          </Link>
                        );
                      })}
                    </div>
                  </li>
                );
              }

              return (
                <li
                  key={menu.title}
                  className={location.pathname === menu.link ? styles.active : ''}
                >
                  <Link
                    to={menu.link}
                    target={menu.link.indexOf('http') === 0 ? '_blank' : '_self'}
                  >
                    {menu.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </ModernDrawer>
    </>
  );
};
