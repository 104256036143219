import { FC, Fragment } from 'react';
import styles from './social.module.scss';
import { socials } from '@constants/social';

interface OwnProps {
  className?: string;
  width: number;
  wrapAt?: number;
}

export const Social: FC<OwnProps> = ({ className = '', width, wrapAt }) => {
  return (
    <ul className={`${styles.wrapper} ${className}`}>
      {socials.map((social, index) => (
        <Fragment key={social.link}>
          {index === wrapAt && <div style={{ width: '100%' }} />}
          <li>
            <a href={social.link} target="_blank">
              <img src={social.icon} loading="lazy" style={{ width }} />
            </a>
          </li>
        </Fragment>
      ))}
    </ul>
  );
};
