import { ChangeEvent, FC, useState } from 'react';
import styles from './index.module.scss';
import { CoinLogo } from '@components/coin-logo';
import { filterPositiveNumber } from '@utils/filter-positive-number';

interface OwnProps {
  className?: string;
  theme?: 'default' | 'highlight';
  balancePrefix?: string;
  value?: string;
  /**
   * Defaults `0.00`
   */
  balance?: string;
  logo: string;
  symbol: string;
  onChange(numberValue: string): void;
}

export const CoinInput: FC<OwnProps> = (props) => {
  const {
    className = '',
    theme = 'default',
    balancePrefix,
    balance = '0.00',
    logo,
    symbol,
    onChange,
    value,
  } = props;
  const [focus, setFocus] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const { str } = filterPositiveNumber(value);
    onChange(str);
  };

  const handleSetMax = () => {
    onChange(balance);
  };

  return (
    <div
      className={`${styles.input} ${styles['theme_' + theme]} ${
        focus ? styles.active : ''
      } ${className}`}
    >
      <CoinLogo src={logo} />
      <p className={styles.symbol}>{symbol}</p>
      <div className={styles.balance}>
        <input
          value={value}
          placeholder="0.00"
          onChange={handleChange}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        <p>
          {balancePrefix}
          <span onClick={handleSetMax}>{balance}</span>
        </p>
      </div>
    </div>
  );
};
