import { DepositOpts } from './wallet-base';

export abstract class OnekeyProvider {
  async getPublicKey(): Promise<string> {
    return '';
  }

  abstract connect(): Promise<string>;
  abstract signMessage(message: string, address: string): Promise<string>;
  abstract isConnected(): Promise<boolean>;
  abstract getAccount(): Promise<string | undefined>;
  abstract getBalance(tokenCoinAddress: string): Promise<string>;
  abstract enableTransaction(): boolean;
  abstract deposit(opts: DepositOpts): Promise<string>;
}
