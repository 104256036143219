import 'normalize.css';
import './assets/styles/bootstrap.css';
import './models';
import ReactDOM from 'react-dom/client';
import { FocaProvider } from 'foca';
import { RouterProvider, createHashRouter } from 'react-router-dom';
import { routers } from './routers/router';
import { Toast } from '@ui/toast';
import { Buffer } from 'buffer';

globalThis.Buffer = Buffer;

const container = document.getElementById('root')!;
const root = ReactDOM.createRoot(container);

root.render(
  <FocaProvider>
    <RouterProvider router={createHashRouter(routers)} />
    <Toast />
  </FocaProvider>,
);
