const reg = /(\.[^.]+)$/;

export const generateSrcset = (image: string) => {
  const x1 = new URL(`../assets/images/${image}`, import.meta.url);
  const x2 = new URL(`../assets/images/${image.replace(reg, '@2x$1')}`, import.meta.url);
  const x3 = new URL(`../assets/images/${image.replace(reg, '@3x$1')}`, import.meta.url);

  return {
    source: x1.href,
    srcset: `${x1.href} 1x, ${x2.href} 2x, ${x3.href} 3x`,
  };
};
