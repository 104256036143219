import { type FC } from 'react';
import RcDialog from 'rc-dialog';
import 'rc-dialog/assets/index.css';
import type { IDialogPropTypes } from 'rc-dialog/es/IDialogPropTypes';
import styles from './index.module.scss';
import closeIcon from '@assets/images/close.svg';

interface OwnProps extends IDialogPropTypes {
  /**
   * Defaults `true`
   */
  centered?: boolean;
}

export const Dialog: FC<OwnProps> = ({
  centered = true,
  wrapClassName = '',
  className = '',
  children,
  ...props
}) => {
  return (
    <RcDialog
      width={448}
      maskClosable={false}
      {...props}
      closeIcon={<img src={closeIcon} />}
      closable={props.closable ?? !!props.title}
      className={`${className} ${styles.dialog}`}
      classNames={{
        wrapper: `${wrapClassName} ${centered ? styles.centered : ''}`,
      }}
    >
      {props.visible ? children : undefined}
    </RcDialog>
  );
};
