import { walletProviders } from '@libs/wallet';
import { WalletBase } from '@libs/wallet/wallet-base';
import { walletExtensionModel } from '@models/wallet-extension.model';
import { useModel } from 'foca';
import { useEffect, useState } from 'react';

export const useWalletNetwork = () => {
  const { network } = useModel(walletExtensionModel);
  return network;
};

export const useWallet = () => {
  const network = useWalletNetwork();
  const { walletName } = useModel(walletExtensionModel);
  const [wallet, setWallet] = useState<WalletBase>();

  useEffect(() => {
    if (!network || !walletName) {
      setWallet(undefined);
      return;
    }

    const wallet = walletProviders.find(
      (item) => item.getMeta().name === walletName && item.getMeta().installed,
    );
    wallet
      ?.setNetwork(network)
      .isConnected()
      .then((is) => {
        is && setWallet(wallet);
      });
  }, [network, walletName]);

  return wallet;
};

export const useWalletAccount = () => {
  const wallet = useWallet();
  const [account, setAccount] = useState<string>();

  useEffect(() => {
    if (!wallet) return;
    wallet.getAccount().then(setAccount);
  }, [wallet]);

  return account;
};

export const useWalletBalance = (tokenCoinAddress: string | undefined) => {
  const wallet = useWallet();
  const [balance, setBalance] = useState('0');

  useEffect(() => {
    if (!wallet || !tokenCoinAddress) {
      setBalance('0');
      return;
    }

    wallet.getBalance(tokenCoinAddress).then(setBalance);
  }, [wallet, tokenCoinAddress]);

  return balance;
};
