import { FC, useEffect } from 'react';
import styles from './index.module.scss';
import { Outlet } from 'react-router-dom';
import { useComputed } from 'foca';
import { passportModel } from '@models/passport.model';
import { useWalletAccount } from '@hooks/use-wallet';

export const Dashboard: FC = () => {
  const address = useWalletAccount();
  const tokenAvailable = useComputed(passportModel.tokenAvailable, address);

  useEffect(() => {
    document.body.classList.add(styles.body!);
    return () => {
      document.body.classList.remove(styles.body!);
    };
  }, []);

  if (!tokenAvailable) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <Outlet />
      </div>
    </div>
  );
};
