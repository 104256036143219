import { store } from 'foca';
import { passportModel } from './passport.model';
import { walletExtensionModel } from './wallet-extension.model';

store.init({
  persist: [
    {
      engine: localStorage,
      key: 'elfin-' + import.meta.env.MODE,
      version: 1,
      models: [passportModel, walletExtensionModel],
    },
  ],
});

if (import.meta.hot) {
  import.meta.hot.accept();
}
