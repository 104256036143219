import { FC, ReactNode } from 'react';
import { menus } from '../routers/menu';
import NotReadyTooltip from './components/not-ready-tooltip';
import { Link, useLocation } from 'react-router-dom';
import styles from './menu.module.scss';
import { Dropdown } from '@ui/dropdown';

interface OwnProps {
  className?: string;
  activeClassName?: string;
  dropdownClassName?: string;
}

export const Menu: FC<OwnProps> = ({
  className = '',
  activeClassName = '',
  dropdownClassName = '',
}) => {
  const location = useLocation();

  return (
    <ul className={`${styles.menus} ${className}`}>
      {menus.map((menu) => {
        if (menu.notReady) {
          return (
            <NotReadyTooltip key={menu.title}>
              <li>{menu.title}</li>
            </NotReadyTooltip>
          );
        }

        if (menu.children?.length) {
          const $overlay: ReactNode = (
            <ul>
              {menu.children.map((child) => (
                <li key={child.title}>
                  <Link
                    key={child.link}
                    to={child.link}
                    target={child.link.indexOf('http') === 0 ? '_blank' : '_self'}
                  >
                    {child.title}
                  </Link>
                </li>
              ))}
            </ul>
          );

          return (
            <Dropdown
              overlay={$overlay}
              key={menu.title}
              overlayClassName={`${styles.menu_dropdown} ${dropdownClassName}`}
              placement="bottom"
              trigger="hover"
            >
              <li>{menu.title}</li>
            </Dropdown>
          );
        }

        return (
          <li key={menu.title} className={location.pathname === menu.link ? activeClassName : ''}>
            <Link to={menu.link} target={menu.link.indexOf('http') === 0 ? '_blank' : '_self'}>
              {menu.title}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};
