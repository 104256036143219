import { tokenCoinModel } from '@models/token-coin.model';
import { useLoading, useModel } from 'foca';
import { useEffect } from 'react';

export const useTokenCoins = () => {
  const coins = useModel(tokenCoinModel);
  const loading = useLoading(tokenCoinModel.queryList);

  useEffect(() => {
    tokenCoinModel.queryList();
  }, []);

  return { coins, loading };
};
