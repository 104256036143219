import { WalletNetwork } from '@constants/wallet-network';

export interface DepositOpts {
  tokenCoinAddress: string;
  amount: string;
  fromAddress: string;
  vaultAddress: string;
}

export abstract class WalletBase {
  protected network!: WalletNetwork;

  setNetwork(network: WalletNetwork) {
    this.network = network;
    return this;
  }

  async getPublicKey(): Promise<string> {
    return '';
  }

  abstract getSupportedChains(): WalletNetwork[];
  abstract getMeta(): { icon: string; name: string; installed: boolean };
  abstract connect(network: WalletNetwork): Promise<string>;
  abstract signMessage(message: string, address: string): Promise<string>;
  abstract isConnected(): Promise<boolean>;
  abstract getAccount(): Promise<string | undefined>;
  abstract getBalance(tokenCoinAddress: string): Promise<string>;
  abstract enableTransaction(): boolean;
  abstract deposit(opts: DepositOpts): Promise<string>;
}
