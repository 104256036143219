import { FC } from 'react';
import styles from './first-page.module.scss';
import bannerTaglineIcon from '@assets/images/banner-tagline.svg';
import binanceIcon from '@assets/images/org-binance.svg';

export const FirstPage: FC = () => {
  return (
    <div className={styles.wrapper}>
      <img className={styles.title} src={bannerTaglineIcon} />
      <p className={styles.sub_title}>
        The <span>1st</span> eSports Metaverse
        <br /> on <span>BTC ecosystem</span>
      </p>
      <div className={styles.powered_by}>
        POWERED BY
        <img src={binanceIcon} />
      </div>
    </div>
  );
};
