import { Dialog } from '@ui/dialog';
import { FC } from 'react';
import styles from './index.module.scss';
import Lottie from 'lottie-react';
import loadingEye from '@assets/lottie/loading-eye.json';

interface OwnProps {
  description: string;
  loading: boolean;
}

export const DialogLoading: FC<OwnProps> = ({ description, loading }) => {
  return (
    <Dialog visible={loading}>
      <Lottie animationData={loadingEye} loop autoplay className={styles.lottie} />
      <p className={styles.title}>Confirming</p>
      <p className={styles.desc}>{description}</p>
    </Dialog>
  );
};
