import { Dialog } from '@ui/dialog';
import { FC, useEffect, useState } from 'react';
import succeedIcon from '@assets/images/dialog-ok.svg';
import arrowLeftIcon from '@assets/images/arrow-left.svg';
import arrowDownIcon from '@assets/images/arrow-down.svg';
import directionDownIcon from '@assets/images/direction-down.svg';
import swapIcon from '@assets/images/swap.svg';
import { Button } from '@ui/button';
import styles from './dialog-transfer.module.scss';
import { Input } from '@ui/input';
import { CoinInput } from '@components/coin-input';
import { useIsolate, useLoading, useModel } from 'foca';
import { GameItem, gameModel } from '@models/game.model';
import { useDepositBalances } from '@hooks/use-deposit-balance';
import { DialogLoading } from '@components/dialog-loading';
import { useTokenCoins } from '@hooks/use-token-coin';
import { CoinItem } from '@models/token-coin.model';
import Decimal from 'decimal.js';
import { CoinLogo } from '@components/coin-logo';
import { Confirm } from '@components/dialog-error';
import { balanceModel } from '@models/balance.model';

enum Step {
  selectGame,
  inputCoin,
  confirm,
  trade,
}

interface OwnProps {
  forceGame?: GameItem;
  defaultType: 'in' | 'out' | 'swap';
  onClose(): void;
  onSuccess?(gameId: number): void;
}

export const DialogTransfer: FC<OwnProps> = (props) => {
  const { forceGame, onClose, defaultType, onSuccess } = props;
  const [step, setStep] = useState<Step>(forceGame ? Step.inputCoin : Step.selectGame);
  const [game, setGame] = useState(forceGame);
  const [coin, setCoin] = useState<CoinItem>();
  const [type, setType] = useState(defaultType === 'swap' ? 'in' : defaultType);
  const [gameAmount, setGameAmount] = useState('');
  const [coinAmount, setCoinAmount] = useState('');
  const model = useIsolate(gameModel);
  const { coins } = useTokenCoins();

  useEffect(() => {
    coins.length && setCoin(coins[0]);
  }, [coins]);

  const handleGameSelected = (game: GameItem) => {
    setGame(game);
    setStep(Step.inputCoin);
  };

  const handleBackToSelectGame = () => {
    setStep(Step.selectGame);
  };

  const handleToConfirm = () => {
    setStep(Step.confirm);
    const myGameAmount = new Decimal(gameAmount).floor();
    const exchangeRate = game!.rates[coin!.id] || '1';
    setGameAmount(myGameAmount.toString());
    setCoinAmount(myGameAmount.div(exchangeRate).toString());
  };

  if (!coin) return null;

  switch (step) {
    case Step.selectGame:
      return (
        <SelectGame
          // @ts-expect-error
          model={model}
          onClose={onClose}
          onSelected={handleGameSelected}
        />
      );
    case Step.inputCoin:
      return (
        <InputCoin
          game={game!}
          coin={coin}
          type={type}
          onTypeChange={setType}
          enableSwap={defaultType === 'swap'}
          onClose={onClose}
          onBack={handleBackToSelectGame}
          gameAmount={gameAmount}
          onGameAmountChange={setGameAmount}
          coinAmount={coinAmount}
          onCoinAmountChange={setCoinAmount}
          onNextStep={handleToConfirm}
        />
      );
    case Step.confirm:
      return (
        <InputConfirm
          game={game!}
          coin={coin}
          type={type}
          gameAmount={gameAmount}
          coinAmount={coinAmount}
          onClose={onClose}
          onNext={() => setStep(Step.trade)}
        />
      );
    case Step.trade:
      return (
        <Trade
          game={game!}
          coin={coin}
          type={type}
          gameAmount={gameAmount}
          coinAmount={coinAmount}
          onClose={onClose}
          onSuccess={onSuccess}
        />
      );
  }
};

const SelectGame: FC<{
  model: typeof gameModel;
  onClose(): void;
  onSelected(game: GameItem): void;
}> = (props) => {
  const { onClose, onSelected, model } = props;
  const [search, setSearch] = useState('');
  const games = useModel(model, (state) => state.all.result);

  useEffect(() => {
    const timer = setTimeout(() => {
      model.queryAllList(search, 1, 100);
    }, 300);
    return () => clearTimeout(timer);
  }, [search]);

  return (
    <Dialog
      visible
      title="Select Game"
      className={`${styles.dialog} ${styles.select_game_dialog}`}
      onClose={onClose}
    >
      <Input
        placeholder="Search for games or coins"
        className={styles.gap}
        theme="highlight"
        onChange={setSearch}
        search
      />
      <ul className={styles.game}>
        {games.map((game) => {
          return (
            <li key={game.id} onClick={() => onSelected(game)}>
              <img src={game.logo} />
              <p className={styles.name}>{game.name}</p>
              <p className={styles.balance}>
                {game.assets_balance}&nbsp;{game.assets_name}
              </p>
            </li>
          );
        })}
      </ul>
    </Dialog>
  );
};

const InputCoin: FC<{
  onClose(): void;
  onBack: () => void;
  enableSwap: boolean;
  type: 'in' | 'out';
  onTypeChange(type: 'in' | 'out'): void;
  game: GameItem;
  coin: CoinItem;
  gameAmount: string;
  onGameAmountChange(amount: string): void;
  coinAmount: string;
  onCoinAmountChange(amount: string): void;
  onNextStep(): void;
}> = (props) => {
  const {
    enableSwap,
    onBack,
    onClose,
    onNextStep,
    type,
    onTypeChange,
    game,
    gameAmount,
    onGameAmountChange,
    coin,
    coinAmount,
    onCoinAmountChange,
  } = props;
  const { balances: coinBalance } = useDepositBalances();
  const gameBalance = game.assets_balance.toString();
  const exchangeRate = game.rates[coin.id] || '1';

  const handleSwap = () => {
    onTypeChange(type === 'in' ? 'out' : 'in');
  };

  const handleCoinAmountChange = (value: string) => {
    onCoinAmountChange(value);
    onGameAmountChange(value === '' ? '' : new Decimal(value).mul(exchangeRate).toString());
  };

  const handleGameAmountChange = (value: string) => {
    onGameAmountChange(value);
    onCoinAmountChange(value === '' ? '' : new Decimal(value).div(exchangeRate).toString());
  };

  const coinInput = (
    <CoinInput
      value={coinAmount}
      onChange={handleCoinAmountChange}
      logo={coin.logo}
      symbol={coin.symbol}
      theme="highlight"
      className={styles.input}
      balance={coinBalance[0]?.amount}
      balancePrefix="Balance: "
    />
  );

  const gameInput = (
    <CoinInput
      value={gameAmount}
      onChange={handleGameAmountChange}
      logo={game.logo}
      symbol={game.assets_name}
      theme="highlight"
      className={styles.input}
      balance={gameBalance}
      balancePrefix="Balance: "
    />
  );

  const insufficient =
    type === 'in'
      ? new Decimal(coinAmount || '0').greaterThan(coinBalance[0]?.amount || 0)
      : new Decimal(gameAmount || '0').greaterThan(gameBalance);

  return (
    <Dialog
      visible
      onClose={onClose}
      title={
        <>
          {enableSwap && <img src={arrowLeftIcon} onClick={onBack} className={styles.back} />}
          <span>&nbsp;&nbsp;Transfer</span>
        </>
      }
      className={`${styles.dialog} ${styles.input_dialog}`}
    >
      <p className={styles.gap}>You pay</p>
      {type === 'in' ? coinInput : gameInput}
      <p className={styles.gap}>You receive</p>
      <div className={styles.swap_icon}>
        {enableSwap ? <img src={swapIcon} onClick={handleSwap} /> : <img src={directionDownIcon} />}
      </div>
      {type === 'out' ? coinInput : gameInput}
      <p className={`${styles.gap} ${styles.center}`}>
        1 {coin.symbol} ≈ {Number(exchangeRate).toFixed(2)} {game.assets_name}
      </p>
      <Button
        className={styles.gap}
        block
        disabled={insufficient || !coinAmount || !gameAmount || new Decimal(coinAmount).isZero()}
        onClick={onNextStep}
      >
        {insufficient ? 'Insufficient balance' : 'Transfer'}
      </Button>
    </Dialog>
  );
};

const InputConfirm: FC<{
  game: GameItem;
  coin: CoinItem;
  type: 'in' | 'out';
  gameAmount: string;
  coinAmount: string;
  onClose(): void;
  onNext(): void;
}> = (props) => {
  const { game, coin, type, gameAmount, coinAmount, onClose, onNext } = props;
  const exchangeRate = game.rates[coin.id] || '1';

  const gameDom = (
    <div className={styles.coin_item}>
      <p>
        {gameAmount} {game.assets_name}
      </p>
      <CoinLogo src={game.logo} />
    </div>
  );

  const coinDom = (
    <div className={styles.coin_item}>
      <p>
        {coinAmount} {coin.symbol}
      </p>
      <CoinLogo src={coin.logo} />
    </div>
  );

  return (
    <Dialog
      visible
      title="Transfer"
      className={`${styles.dialog} ${styles.confirm_dialog}`}
      onClose={onClose}
    >
      <div className={`${styles.gap} ${styles.coins}`}>
        {type === 'in' ? coinDom : gameDom}
        <div className={styles.divider}>
          <img src={arrowDownIcon} />
        </div>
        {type === 'out' ? coinDom : gameDom}
      </div>
      <p className={`${styles.gap} ${styles.center}`}>
        1 {coin.symbol} ≈ {Number(exchangeRate).toFixed(2)} {game.assets_name}
      </p>
      <Button className={styles.gap} block onClick={onNext}>
        Transfer
      </Button>
    </Dialog>
  );
};

const Trade: FC<{
  game: GameItem;
  coin: CoinItem;
  type: 'in' | 'out';
  gameAmount: string;
  coinAmount: string;
  onClose(): void;
  onSuccess?(gameId: number): void;
}> = (props) => {
  const { game, coin, type, gameAmount, coinAmount, onClose, onSuccess } = props;
  const loading = useLoading(gameModel.transfer);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const description = `Transfer ${
    type === 'in' ? `${coinAmount} ${coin.symbol}` : `${gameAmount} ${game.assets_name}`
  } to ${type === 'out' ? `${coinAmount} ${coin.symbol}` : `${gameAmount} ${game.assets_name}`}`;

  useEffect(() => {
    gameModel
      .transfer({
        gameId: game.id,
        gameAmount,
        tokenCoinId: coin.id,
        type,
      })
      .then(() => {
        setSuccess(true);
        balanceModel.queryList();
        gameModel.getGameDetail(game.id);
        onSuccess?.(game.id);
      })
      .catch(() => {
        setError(true);
      });
  }, []);

  if (loading) {
    return <DialogLoading loading description={description} />;
  }

  if (error)
    return (
      <Confirm
        onConfirm={onClose}
        title="Oops!"
        hasCancelButton={false}
        description="Something went wrong"
      />
    );

  if (success) {
    return (
      <Dialog visible className={`${styles.dialog} ${styles.trade_dialog}`}>
        <div className={styles.center}>
          <img src={succeedIcon} className={styles.icon} />
        </div>
        <p className={styles.title}>Succeeded</p>
        <p className={styles.desc}>{description}</p>
        <Button className={styles.gap} block onClick={onClose}>
          Close
        </Button>
      </Dialog>
    );
  }

  return null;
};
