import twitterIcon from '../assets/images/social-twitter.svg';
import redditIcon from '../assets/images/social-reddit.svg';
import telegramIcon from '../assets/images/social-telegram.svg';
import discordIcon from '../assets/images/social-discord.svg';
import mediumIcon from '../assets/images/social-medium.svg';
import youtubeIcon from '../assets/images/social-youtube.svg';
import instagramIcon from '../assets/images/social-instagram.svg';

export const socials: { icon: string; link: string }[] = [
  {
    icon: twitterIcon,
    link: 'https://twitter.com/ElfinGames',
  },
  {
    icon: redditIcon,
    link: 'https://www.reddit.com/r/ElfinKingdom/',
  },
  {
    icon: discordIcon,
    link: 'https://discord.com/invite/dBNZCw2chm',
  },
  {
    icon: mediumIcon,
    link: 'https://medium.com/@elfingames',
  },
  {
    icon: telegramIcon,
    link: 'https://t.me/ElfinKDM',
  },
  {
    icon: youtubeIcon,
    link: 'https://www.youtube.com/@ElfinGamesOfficial',
  },
  {
    icon: instagramIcon,
    link: 'https://www.instagram.com/elfingames_official/',
  },
];
