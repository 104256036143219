import { WalletNetwork } from '@constants/wallet-network';
import { WalletBase } from '@libs/wallet/wallet-base';
import { Button } from '@ui/button';
import { Dialog } from '@ui/dialog';
import { FC, useState } from 'react';
import styles from './add-wallet.module.scss';
import { toast } from 'react-toastify';
import { passportModel } from '@models/passport.model';
import { SelectWallet } from '@components/connect-button/select-wallet';
import { SelectNetwork } from '@components/connect-button/select-network';
import { walletModel } from '@models/wallet.model';

export const AddWallet: FC<{ onAdded(): void }> = ({ onAdded }) => {
  const [dialog, setDialog] = useState(false);
  const [network, setNetwork] = useState<WalletNetwork>();

  const handleClose = () => {
    setDialog(false);
    setNetwork(undefined);
  };

  const handleConnect = async (provider: WalletBase) => {
    handleClose();
    if (!network) return;

    let address: string;
    try {
      address = await provider.connect(network);
    } catch (e) {
      toast.error('Connect failed');
      throw e;
    }

    const nonce = await passportModel.getNonce(address);
    const signature = await provider.signMessage(nonce, address);
    const publicKey = await provider.getPublicKey();

    await walletModel.addWallet({
      walletAddress: address,
      network,
      signature,
      publicKey,
    });
    onAdded();
    setTimeout(() => {
      toast.success('Wallet added!');
    }, 50);
  };

  return (
    <>
      <Button size="default" onClick={() => setDialog(true)}>
        Add Wallet
      </Button>
      <Dialog
        visible={dialog}
        className={styles.dialog}
        onClose={handleClose}
        title={network ? 'Connect a wallet' : 'Select a Network'}
      >
        {network ? (
          <SelectWallet
            network={network}
            onSelected={handleConnect}
            onBack={() => setNetwork(undefined)}
          />
        ) : (
          <SelectNetwork onSelected={setNetwork} />
        )}
      </Dialog>
    </>
  );
};
