import { FC, useEffect, useState } from 'react';
import styles from './index.module.scss';
import leftIcon from '@assets/images/direction-left.svg';
import rightIcon from '@assets/images/direction-right.svg';
import { Button } from '@ui/button';
import { Input } from '@ui/input';

interface OwnProps {
  page: number;
  pageSize: number;
  totalRows: number;
  onChange(page: number, pageSize: number): void;
  className?: string;
}

export const Pagination: FC<OwnProps> = (props) => {
  const { page, pageSize, totalRows, onChange, className = '' } = props;
  const totalPage = Math.ceil(totalRows / pageSize);
  const [inputPage, setInputPage] = useState(page.toString());

  useEffect(() => {
    setInputPage(page.toString());
  }, [page]);

  const handleToPrev = () => {
    onChange(page - 1, pageSize);
  };
  const handleToNext = () => {
    onChange(page + 1, pageSize);
  };
  const handleToPage = () => {
    let nextPage = Number(inputPage) || 1;
    if (nextPage < 1) {
      nextPage = 1;
    } else if (nextPage > totalPage) {
      nextPage = totalPage;
    }
    if (nextPage !== page) {
      onChange(nextPage, pageSize);
    }
  };

  if (totalPage <= 1) return null;

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <div />
      <div>
        <Button
          type="border"
          size="default"
          className={styles.btn}
          onClick={handleToPrev}
          disabled={page === 1}
        >
          <img src={leftIcon} />
        </Button>
        <Button
          type="primary"
          size="default"
          className={styles.btn}
          onClick={handleToNext}
          disabled={page === totalPage}
        >
          Next page
          <img src={rightIcon} />
        </Button>
      </div>
      <div className={styles.right}>
        <p>Page</p>
        <Input
          value={inputPage}
          className={styles.input}
          onEnter={handleToPage}
          onChange={setInputPage}
        />
        <p>of {totalPage}</p>
      </div>
    </div>
  );
};
