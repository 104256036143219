import { ChangeEvent, FC, KeyboardEvent, useRef, useState } from 'react';
import styles from './index.module.scss';
import searchIcon from '@assets/images/search.svg';

interface OwnProps {
  placeholder?: string;
  className?: string;
  theme?: 'default' | 'highlight';
  onChange?(text: string): void;
  onEnter?(): void;
  /**
   * Defaults `false`
   */
  search?: boolean;
  value?: string;
}

export const Input: FC<OwnProps> = ({
  placeholder = 'Search',
  search = false,
  className = '',
  theme = 'default',
  value,
  onChange,
  onEnter,
}) => {
  const [focus, setFocus] = useState(false);
  const [internalValue, setInternalValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    inputRef.current?.focus();
  };
  const handleFocus = () => setFocus(true);
  const handleBlur = () => setFocus(false);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInternalValue(e.target.value);
    onChange?.(e.target.value);
  };
  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onEnter?.();
    }
  };

  return (
    <div
      onClick={handleClick}
      className={`${styles.wrapper} ${focus ? styles.active : ''} ${
        styles['theme_' + theme]
      } ${className}`}
    >
      {search && <img src={searchIcon} className={styles.icon} />}
      <input
        ref={inputRef}
        type="text"
        value={value === undefined ? internalValue : value}
        placeholder={placeholder}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
      />
    </div>
  );
};
