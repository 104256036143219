import { FC, useEffect, useState } from 'react';
import styles from './index.module.scss';
import Table from '@ui/table';
import { AddWallet } from './components/add-wallet';
import { useLoading, useModel } from 'foca';
import { walletModel } from '@models/wallet.model';
import { addressToDot } from '@utils/address-to-dot';
import { networkMeta } from '@constants/wallet-network';
import { passportModel } from '@models/passport.model';
import { Confirm } from '@components/dialog-error';
import { toast } from 'react-toastify';

export const DashboardSetting: FC = () => {
  const wallets = useModel(walletModel);
  const address = useModel(passportModel, (state) => state.address);
  const [unlinkId, setUnlinkId] = useState(0);
  const loading = useLoading(walletModel.fetchWallets);

  useEffect(() => {
    walletModel.fetchWallets();
  }, []);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <p>Wallets Settings</p>
          <AddWallet onAdded={walletModel.fetchWallets} />
        </div>
        <Table
          data={wallets}
          dataKey="id"
          loading={loading}
          head={[
            {
              title: 'Address',
            },
            {
              title: 'Network',
            },
            {
              title: 'Action',
            },
          ]}
          render={(row) => {
            return (
              <tr className={styles.table_row}>
                <td>
                  <p>{addressToDot(row.wallet_address)}</p>
                </td>
                <td>
                  <div className={styles.network}>
                    <img src={networkMeta[row.network].icon} />
                    <p>{networkMeta[row.network].label}</p>
                  </div>
                </td>
                <td>
                  {address === row.wallet_address ? (
                    <p className={styles.operation_disabled}>Can’t unlink current wallet</p>
                  ) : wallets.length === 1 ? (
                    <p className={styles.operation_disabled}>Can’t unlink last wallet</p>
                  ) : (
                    <a className={styles.unlink} onClick={() => setUnlinkId(row.id)}>
                      Unlink
                    </a>
                  )}
                </td>
              </tr>
            );
          }}
        />
      </div>
      {!!unlinkId && (
        <Confirm
          className={styles.unlink_confirm}
          title="Unlink Wallet"
          description={`Are you sure you want to Unlink Wallet ${wallets.find(
            (item) => item.id === unlinkId,
          )?.wallet_address}`}
          onCancel={() => setUnlinkId(0)}
          onConfirm={async () => {
            await walletModel.deleteWallet(unlinkId);
            setUnlinkId(0);
            toast.success('Wallet unlinked');
            walletModel.fetchWallets();
          }}
        />
      )}
    </>
  );
};
