import { OnekeyProvider } from './onekey-provider';
import {
  etchSwitchChain,
  ethDeposit,
  ethGetSelectedAddress,
  ethGetTokenCoinBalance,
  ethPersonalSignMessage,
} from './common';
import Web3 from 'web3';
import { DepositOpts } from './wallet-base';

export class OnekeyETHProvider extends OnekeyProvider {
  protected readonly provider: ONEKEY_ETH;
  protected readonly web3: Web3;

  constructor() {
    super();
    const provider = window.$onekey?.ethereum;
    if (!provider) throw new Error('OneKey Not installed.');
    this.provider = provider;
    this.web3 = new Web3(this.provider);
    // provider.chainId = '0x80';
    // provider.networkVersion = '128';
  }

  override async connect(): Promise<string> {
    await etchSwitchChain(this.provider);
    const addresses = await this.provider.request({ method: 'eth_requestAccounts' });
    return addresses[0]!;
  }

  override signMessage(message: string, address: string): Promise<string> {
    return ethPersonalSignMessage(this.provider, address, message);
  }

  override async isConnected(): Promise<boolean> {
    return this.provider.isConnected();
  }

  override async getAccount(): Promise<string | undefined> {
    return ethGetSelectedAddress(this.provider);
  }

  override async getBalance(tokenCoinAddress: string): Promise<string> {
    const address = await this.getAccount();
    return ethGetTokenCoinBalance(this.web3, tokenCoinAddress, address!);
  }

  override enableTransaction(): boolean {
    return true;
  }

  override deposit(opts: DepositOpts): Promise<string> {
    return ethDeposit(this.web3, opts);
  }
}
