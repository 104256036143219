import { FC, useState } from 'react';
import styles from './deposit.module.scss';
import { Button } from '@ui/button';
import { CoinInput } from '@components/coin-input';
import { useTokenCoins } from '@hooks/use-token-coin';
import { DialogTransfer } from './dialog-transfer';
import { DialogLoading } from '@components/dialog-loading';
import { Dialog } from '@ui/dialog';
import icon from '@assets/images/dialog-ok.svg';
import Decimal from 'decimal.js';
import { useWallet, useWalletAccount, useWalletBalance } from '@hooks/use-wallet';
import { toast } from 'react-toastify';

export const Deposit: FC = () => {
  const { coins } = useTokenCoins();
  const firstCoin = coins[0];
  const address = useWalletAccount();
  const balance = useWalletBalance(firstCoin?.address);
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const [transferToGame, setTransferToGame] = useState(false);
  const provider = useWallet();

  const handleSubmit = async () => {
    if (!provider || !address) return;
    if (!provider.enableTransaction()) return;
    try {
      setLoading(true);
      await provider.deposit({
        tokenCoinAddress: firstCoin!.address,
        amount,
        fromAddress: address,
        vaultAddress: '0x43707C6Bb6202a5E1007356539a925C052EA9767',
      });
      setTransactionSuccess(true);
    } catch (e) {
      const err = e as Error | { error: { message: string } };
      const msg = err instanceof Error ? err.message : err.error ? err.error.message : '';
      toast.error('Deposit failed: ' + msg);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSuccess = () => {
    setTransactionSuccess(false);
    setAmount('');
  };

  const handleTransfer = () => {
    handleCloseSuccess();
    setTransferToGame(true);
  };

  const insufficient = !!balance && !!amount && new Decimal(amount).greaterThan(balance);

  if (!firstCoin) return null;

  return (
    <>
      <div className={styles.wrapper}>
        <p className={styles.desc}>Deposit USDT to start your Web3 game journey</p>
        <CoinInput
          className={styles.input}
          logo={firstCoin.logo}
          symbol={firstCoin.symbol}
          balance={balance}
          balancePrefix="Max: "
          onChange={setAmount}
          value={amount}
        />
        <Button
          block
          size="large"
          className={styles.button}
          onClick={handleSubmit}
          disabled={!amount || insufficient}
        >
          {insufficient ? 'Insufficient balance' : 'Deposit'}
        </Button>
      </div>
      <DialogLoading loading={loading} description={`Depositing ${amount} ${firstCoin.symbol}`} />
      {transferToGame && (
        <DialogTransfer defaultType="swap" onClose={() => setTransferToGame(false)} />
      )}
      <Dialog visible={transactionSuccess} className={styles.dialog} onClose={handleCloseSuccess}>
        <img src={icon} />
        <p className={styles.title}>All Done!</p>
        <p className={styles.desc}>
          You deposited {amount} {firstCoin.symbol}
        </p>
        <p className={styles.desc}>Transfer funds to a game to start your Web3 game journey</p>
        <div className={styles.buttons}>
          <Button onClick={handleTransfer}>Transfer now</Button>
          <Button type="border" onClick={handleCloseSuccess}>
            Ok
          </Button>
        </div>
      </Dialog>
    </>
  );
};
