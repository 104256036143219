import { Tab } from '@ui/tab';
import { FC } from 'react';
import { Event } from './components/event';
import { EventType } from '@constants/event-type';
import styles from './index.module.scss';

export const DashboardRecords: FC = () => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>Record</p>
      <Tab
        headerClassName={styles.tabs}
        items={[
          { label: 'All', panel: () => <Event /> },
          { label: 'Transfer', panel: () => <Event eventType={EventType.transfer} /> },
          { label: 'Game', panel: () => <Event eventType={EventType.game} /> },
          { label: 'Withdraw', panel: () => <Event eventType={EventType.withdraw} /> },
          { label: 'Deposit', panel: () => <Event eventType={EventType.deposit} /> },
        ]}
      />
    </div>
  );
};
