const production = {
  /**
   * Register from https://cloud.walletconnect.com
   */
  wallet_project_id: '',
  api_host: '',
  is_mainnet: true,
  showSignInButton: true,
  bsc_chain_id: '0x' + (56).toString(16),
};

export type Config = typeof production;

export default production;
