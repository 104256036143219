import { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import beforeIcon from '@assets/images/section-title-before.svg';
import afterIcon from '@assets/images/section-title-after.svg';

interface OwnProps {
  children: ReactNode;
  top: number;
  bottom: number;
  mobileTop: number;
  mobileBottom: number;
  mobileTitle?: ReactNode;
  decorator?: boolean;
}

export const SectionTitle: FC<OwnProps> = ({
  children,
  top,
  bottom,
  mobileTop,
  mobileBottom,
  mobileTitle,
  decorator = true,
}) => {
  return (
    <>
      <div
        className={`${styles.wrapper} ${styles.desktop}`}
        style={{ paddingTop: top, paddingBottom: bottom }}
      >
        {decorator && <img src={beforeIcon} />}
        <div className={styles.section_title}>{children}</div>
        {decorator && <img src={afterIcon} />}
      </div>
      <div
        className={`${styles.wrapper} ${styles.mobile}`}
        style={{ paddingTop: mobileTop, paddingBottom: mobileBottom }}
      >
        {decorator && <img src={beforeIcon} />}
        <div className={styles.section_title}>{mobileTitle || children}</div>
        {decorator && <img src={afterIcon} />}
      </div>
    </>
  );
};
