import { defineModel } from 'foca';
import { http } from '@libs/http';

export interface CoinItem {
  id: number;
  symbol: string;
  decimals: number;
  address: `0x${string}`;
  logo: string;
}

export const tokenCoinModel = defineModel('token-coin', {
  initialState: <CoinItem[]>[],
  methods: {
    async queryList() {
      const result = await http.get<CoinItem[]>('/client/balance/tokens');
      this.setState(result);
    },
  },
});
