import production, { Config } from './production';
import development from './development';
import integration from './integration';
import homepage from './homepage';

const configs = {
  production,
  integration,
  development,
  homepage,
};

export const config: Config =
  configs[(import.meta.env.MODE || 'development') as keyof typeof configs];
