import 'rc-dropdown/assets/index.css';
import { type FC } from 'react';
import RcDropdown from 'rc-dropdown';
import type { DropdownProps } from 'rc-dropdown/es/Dropdown';
import styles from './index.module.scss';

export const Dropdown: FC<DropdownProps> = (props) => {
  const { overlayClassName = '' } = props;

  return (
    <RcDropdown
      trigger="click"
      {...props}
      overlayClassName={overlayClassName + ' ' + styles.dropdown}
    />
  );
};
