import { FC } from 'react';
import styles from './partner.module.scss';
import { SectionTitle } from '@components/section-title';
import { generateSrcset } from '@utils/generate-srcset';
import { partners } from '@constants/partner';
import { orgs } from '@constants/org';

export const Partner: FC = () => {
  return (
    <div className={styles.wrapper}>
      <SectionTitle top={200} bottom={100} mobileTop={60} mobileBottom={40}>
        Investors
      </SectionTitle>
      <ul className={styles.orgs}>
        {orgs.map((org) => {
          const { source, srcset } = generateSrcset(org.image);
          return (
            <li key={org.image}>
              <img src={source} srcSet={srcset} loading="lazy" />
            </li>
          );
        })}
      </ul>
      <SectionTitle top={100} bottom={50} mobileTop={40} mobileBottom={40} decorator={false}>
        Ecosystem Partners
      </SectionTitle>
      <ul className={styles.partners}>
        {partners.map((partner) => {
          const { source } = generateSrcset(partner.image);

          return (
            <li key={partner.image}>
              <img
                src={source}
                loading="lazy"
                className={styles.desktop}
                style={{ height: partner.height }}
              />
              <img
                src={source}
                loading="lazy"
                className={styles.mobile}
                style={{ height: partner.height * 0.4 }}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
