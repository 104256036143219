import { type FC, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './index.module.scss';
import checkIcon from '@assets/images/check-filled.svg';
import errorIcon from '@assets/images/error-filled.svg';

export const Toast: FC = () => {
  useEffect(() => {
    return toast.onChange(({ status, id }) => {
      if (status === 'added' && !document.hasFocus()) {
        setTimeout(() => {
          toast.dismiss(id);
        }, 5000);
      }
    });
  }, []);

  return (
    <div className={styles.toastContent}>
      <ToastContainer
        pauseOnFocusLoss={false}
        icon={({ type }) => {
          switch (type) {
            case 'success':
            case 'default':
              return <img className={styles.icon} src={checkIcon} />;
            default:
              return <img className={styles.icon} src={errorIcon} />;
          }
        }}
        closeButton={<span />}
        hideProgressBar
        theme="light"
        className={styles.wrapper}
        toastClassName={styles.toast}
      />
    </div>
  );
};
