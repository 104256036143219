import { FC } from 'react';
import { Social } from './social';
import styles from './community.module.scss';
import user1 from '../../../assets/images/user-1.png';
import user2 from '../../../assets/images/user-2.png';
import user3 from '../../../assets/images/user-3.png';
import user4 from '../../../assets/images/user-4.png';
import user5 from '../../../assets/images/user-5.png';
import user6 from '../../../assets/images/user-6.png';
import user11 from '../../../assets/images/user-11.png';
import user12 from '../../../assets/images/user-12.png';
import user13 from '../../../assets/images/user-13.png';
import user14 from '../../../assets/images/user-14.png';
import user15 from '../../../assets/images/user-15.png';
import user16 from '../../../assets/images/user-16.png';
import user17 from '../../../assets/images/user-17.png';
import { SectionTitle } from '@components/section-title';

export const Community: FC = () => {
  return (
    <div className={styles.wrapper}>
      <SectionTitle top={300} bottom={20} mobileTop={80} mobileBottom={32}>
        Join The Community
      </SectionTitle>
      <p className={styles.p_1}>Become part of a creative and vibrant community.</p>
      <Social width={60} className={styles.desktop_socials} />
      <Social width={40} wrapAt={4} className={styles.mobile_socials} />
      <div className={styles.users}>
        <div>
          <img src={user1} />
          <img src={user2} />
          <img src={user3} />
          <img src={user4} />
          <img src={user5} />
          <img src={user6} />
          <img src={user1} />
          <img src={user2} />
          <img src={user3} />
          <img src={user4} />
          <img src={user5} />
          <img src={user6} />
          <img src={user1} />
          <img src={user2} />
          <img src={user3} />
          <img src={user4} />
        </div>
        <div className={styles.mobile_users}>
          <img src={user11} />
          <img src={user16} />
          <img src={user4} />
          <img src={user5} />
          <img src={user11} />
          <img src={user12} />
          <img src={user13} />
          <img src={user11} />
          <img src={user16} />
          <img src={user4} />
          <img src={user5} />
          <img src={user11} />
          <img src={user12} />
          <img src={user13} />
          <img src={user11} />
          <img src={user16} />
          <img src={user4} />
          <img src={user5} />
          <img src={user11} />
          <img src={user12} />
          <img src={user13} />
        </div>
        <div>
          <img src={user11} />
          <img src={user12} />
          <img src={user13} />
          <img src={user14} />
          <img src={user15} />
          <img src={user16} />
          <img src={user11} />
          <img src={user12} />
          <img src={user13} />
          <img src={user14} />
          <img src={user15} />
          <img src={user17} />
          <img src={user11} />
          <img src={user12} />
          <img src={user13} />
          <img src={user14} />
          <img src={user15} className={styles.desktop_user} />
        </div>
      </div>
      <p className={styles.p_2}>170K+ Registered Users</p>
    </div>
  );
};
