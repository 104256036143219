export const orgs: {
  image: string;
}[] = [
  {
    image: 'org-binance.png',
  },
  {
    image: 'org-alameda.png',
  },
];
