import { FC, Fragment, ReactNode, useState } from 'react';
import styles from './index.module.scss';

interface OwnProps {
  items: { label: string; panel: () => ReactNode }[];
  headerClassName?: string;
  type?: 'default' | 'card';
}

export const Tab: FC<OwnProps> = ({ items, headerClassName = '', type = 'default' }) => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <>
      <ul className={`${styles.tabs} ${type === 'card' ? styles.card : ''} ${headerClassName}`}>
        {items.map((tab, index) => {
          return (
            <li
              key={tab.label}
              className={tabIndex === index ? styles.active : ''}
              onClick={() => setTabIndex(index)}
            >
              {tab.label}
            </li>
          );
        })}
      </ul>
      <div className={styles.tabs_content}>
        {items.map((tab, index) => {
          return tabIndex === index ? <Fragment key={tab.label}>{tab.panel()}</Fragment> : null;
        })}
      </div>
    </>
  );
};
