export const teams: { name: string; desc: string; link: string; image: string }[] = [
  {
    name: 'Leo Yeung',
    desc: 'Team Leader',
    link: 'https://www.linkedin.com/in/leo-yeung-416416133/',
    image: 'team-leo-yeung.png',
  },
  {
    name: 'Limic Lee',
    desc: 'Front End Developer',
    link: 'https://www.linkedin.com/in/limic-lee-b20855175/',
    image: 'team-limic-lee.png',
  },
  {
    name: 'Dickson Chow',
    desc: 'Project Manager',
    link: 'https://www.linkedin.com/in/dicksonboychow/',
    image: 'team-dickson-chow.png',
  },
  {
    name: 'Bill Tsoi',
    desc: 'Senior Web and UX Designer',
    link: 'https://www.linkedin.com/in/bill-tsoi/',
    image: 'team-bill-tsoi.png',
  },
  {
    name: 'Tsui SzeLok',
    desc: 'Front End Developer',
    link: 'https://www.linkedin.com/in/sze-lok-tsui-1a139811b/',
    image: 'team-tsui-szelok.png',
  },
  {
    name: 'Pak ng',
    desc: 'Senior Web and UX Designer',
    link: 'https://www.linkedin.com/in/ray-ng-707693170/',
    image: 'team-pak-ng.png',
  },
  {
    name: 'Matthew Wong',
    desc: 'Senior Web and UX Designer',
    link: 'https://www.linkedin.com/in/matthew-wong-703b2a123/',
    image: 'team-matthew-wong.png',
  },
];
