import { http } from '@libs/http';
import { defineModel } from 'foca';

export interface NFTItem {
  item_id: number;
  url: string;
  rare: boolean;
}

export const flashModel = defineModel('flash', {
  initialState: {
    connected: false,
    nfts: <NFTItem[]>[],
  },
  methods: {
    async checkConnected() {
      const result = await http.get<{ connected: boolean }>('/client/flash/connection');
      this.setState({
        connected: result.connected,
      });
    },
    async auth(code: string) {
      await http.post('/client/flash/auth', {
        code,
      });
      this.setState({
        connected: true,
      });
    },
    async getNfts() {
      const result = await http.get<NFTItem[]>('/client/flash/nfts');
      this.setState({
        nfts: result,
      });
    },
  },
});
