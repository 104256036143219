import { generateSrcset } from '@utils/generate-srcset';
import { FC } from 'react';
import styles from './index.module.scss';

const { source, srcset } = generateSrcset('empty.png');

export const Empty: FC = () => {
  return (
    <div className={styles.wrapper}>
      <img src={source} srcSet={srcset} />
      <p>No data yet</p>
    </div>
  );
};
