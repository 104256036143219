import { FC } from 'react';
import styles from './land.module.scss';
import { NFTItem } from '@models/flash.model';

interface OwnProps {
  land: NFTItem;
  index: number;
}

export const Land: FC<OwnProps> = ({ land, index }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p>NO.{index + 1}</p>
        <div className={styles.tag}>Unpledged</div>
      </div>
      <img src={land.url} className={styles.logo} loading="lazy" />
      <ul className={styles.meta}>
        <li>
          <span>Serial Number</span>
          <span>NO.{land.item_id}</span>
        </li>
        <li>
          <span>Level</span>
          <span>Land</span>
        </li>
        <li>
          <span>Type</span>
          <span>Red</span>
        </li>
        <li>
          <span>Rarity</span>
          <span>{land.rare ? 'Rare' : 'Common'}</span>
        </li>
      </ul>
      <div className={styles.line} />
      <div className={styles.operation}>
        <p>Grounding</p>
        <div className={styles.divide} />
        <p>Transfer</p>
        <div className={styles.divide} />
        <p>Pledge</p>
      </div>
    </div>
  );
};
