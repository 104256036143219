import { FC } from 'react';
import styles from './index.module.scss';
import bscIcon from '@assets/images/token-coin-bsc.png';
import btcIcon from '@assets/images/token-coin-btc.png';
import directionRightIcon from '@assets/images/direction-right.svg';
import { WalletNetwork } from '@constants/wallet-network';

interface OwnProps {
  onSelected(network: WalletNetwork): void;
}

export const SelectNetwork: FC<OwnProps> = ({ onSelected }) => {
  return (
    <>
      <div className={styles.box} onClick={() => onSelected(WalletNetwork.bnbSmartChain)}>
        <img src={bscIcon} className={styles.logo} />
        <p>BNB Chain</p>
        <img src={directionRightIcon} className={styles.icon} />
      </div>
      <div className={styles.box} onClick={() => onSelected(WalletNetwork.bitcoin)}>
        <img src={btcIcon} className={styles.logo} />
        <p>Bitcoin</p>
        <img src={directionRightIcon} className={styles.icon} />
      </div>
    </>
  );
};
