import { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import loadingEye from '@assets/lottie/loading-eye.json';
import Lottie from 'lottie-react';

interface OwnProps {
  children?: ReactNode;
  spinning?: boolean;
}

export const Loading: FC<OwnProps> = ({ children, spinning }) => {
  return (
    <div
      className={`${styles.wrapper} ${children === undefined ? styles.no_children : ''} ${
        spinning ? styles.loading : ''
      }`}
    >
      <div className={styles.mask}>
        <Lottie animationData={loadingEye} loop autoplay className={styles.lottie} />
      </div>
      {children}
    </div>
  );
};
