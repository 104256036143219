import { balanceModel } from '@models/balance.model';
import { useLoading, useModel } from 'foca';
import { debounce } from 'lodash-es';
import { useEffect } from 'react';
import { useTokenCoins } from './use-token-coin';

const request = debounce(balanceModel.queryList, 50, { trailing: true });

export function useDepositBalances() {
  const { coins } = useTokenCoins();
  const balances = useModel(balanceModel).map((item) => ({
    ...item,
    token_coin: coins.find((coin) => coin.id === item.token_coin_id),
  }));
  const loading = useLoading(balanceModel.queryList);

  useEffect(() => {
    request();
  }, []);

  return { balances, loading };
}
