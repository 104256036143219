import { WalletNetwork } from '@constants/wallet-network';
import { WalletBase } from '@libs/wallet/wallet-base';
import { defineModel } from 'foca';

const initialState: {
  network?: WalletNetwork;
  walletName?: string;
  connecting: boolean;
} = {
  connecting: false,
};

export const walletExtensionModel = defineModel('wallet-extension', {
  initialState,
  reducers: {
    update(state, network: WalletNetwork, provider: WalletBase) {
      state.network = network;
      state.walletName = provider.getMeta().name;
    },
    reset() {
      return this.initialState;
    },
    connecting(state, is: boolean) {
      state.connecting = is;
    },
  },
  persist: {
    dump(state) {
      return state;
    },
    load(state) {
      return { ...state, connecting: false };
    },
  },
});
