import { AxiosError, axios } from 'foca-axios';
import { config } from '../configs';
import { passportModel } from '@models/passport.model';
import { toast } from 'react-toastify';

export const http = axios.create({
  baseURL: config.api_host,
  cache: { enable: false },
});

http.interceptors.request.use((config) => {
  config.headers ||= {};
  config.headers['Authorization'] = `Bearer ${passportModel.state.token}`;
  return config;
});

http.interceptors.response.use(undefined, (err: AxiosError<string | object>) => {
  if (err.response?.status === 401) {
    passportModel.resetToken();
  }
  toast(
    // @ts-ignore
    // eslint-disable-next-line
    err.response?.data?.message?.toString() ||
      err.response?.data.toString() ||
      err.message ||
      'Network Error',
    { type: 'error' },
  );
  throw err;
});
