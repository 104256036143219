import { erc20ABI } from 'src/configs/erc20-abi';
import { Web3 } from 'web3';
import { DepositOpts } from './wallet-base';
import { config } from '@config';
import { bsc, bscTestnet } from 'viem/chains';

export const ethGetBalance = async (provider: typeof window.ethereum, address: string) => {
  const balance = await provider.request({
    method: 'eth_getBalance',
    params: [address, 'latest'],
  });
  if (!balance) return '0';
  return Web3.utils.fromWei(balance, 'ether');
};

export const ethGetTokenCoinBalance = async (
  web3: Web3,
  tokenCoinAddress: string,
  address: string,
) => {
  const contract = new web3.eth.Contract(erc20ABI, tokenCoinAddress);
  const balance = await contract.methods.balanceOf(address).call();
  if (!balance) return '0';
  return Web3.utils.fromWei(balance, 'ether');
};

export const ethPersonalSignMessage = async (
  provider: typeof window.ethereum,
  address: string,
  message: string,
) => {
  return provider.request({
    method: 'personal_sign',
    params: [`0x${Buffer.from(message, 'utf8').toString('hex')}`, address],
  });
};

export const ethGetSelectedAddress = async (provider: typeof window.ethereum) => {
  /**
   * Warning:
   * MetaMask: 'ethereum.selectedAddress' is deprecated and may be removed in the future.
   * Please use the 'eth_accounts' RPC method instead.
   */
  if (provider.selectedAddress) {
    return provider.selectedAddress;
  }

  const accounts = await provider.request({ method: 'eth_accounts' });
  return accounts[0];
};

export const ethDeposit = async (web3: Web3, opts: DepositOpts) => {
  const { vaultAddress, tokenCoinAddress, fromAddress, amount } = opts;
  const wei = web3.utils.toWei(amount, 'ether');
  const contract = new web3.eth.Contract(erc20ABI, tokenCoinAddress);
  const gasPrice = await web3.eth.getGasPrice();

  const obj = await contract.methods.transfer(vaultAddress, wei).send({
    from: fromAddress,
    gasPrice: web3.utils.toHex(gasPrice),
  });
  return obj.blockHash;
};

export const etchSwitchChain = async (provider: typeof window.ethereum) => {
  if (provider.chainId === config.bsc_chain_id) return;

  try {
    await provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: config.bsc_chain_id }],
    });
  } catch (err) {
    if (err && (err as { code: number }).code === 4902) {
      const chain = Web3.utils.toHex(bscTestnet.id) === config.bsc_chain_id ? bscTestnet : bsc;
      await provider.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainName: chain.name,
            chainId: Web3.utils.toHex(chain.id),
            nativeCurrency: chain.nativeCurrency,
            rpcUrls: Array.from(chain.rpcUrls.default.http),
            blockExplorerUrls: [chain.blockExplorers.default.url],
          },
        ],
      });
    } else {
      throw err;
    }
  }
};
