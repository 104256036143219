import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/grid';
import 'swiper/css/pagination';

import { SectionTitle } from '@components/section-title';
import { FC } from 'react';
import { Navigation, Grid, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './team.module.scss';
import { generateSrcset } from '@utils/generate-srcset';
import linkedinIcon from '@assets/images/linkedin.svg';
import directionRightIcon from '@assets/images/direction-right.svg';
import directionLeftIcon from '@assets/images/direction-left.svg';
import { teams } from '@constants/team';

export const Team: FC = () => {
  const $slides = teams.map((item) => {
    const { source } = generateSrcset(item.image);

    return (
      <SwiperSlide key={item.name} className={styles.swiper_item}>
        <img src={source} className={styles.avatar} />
        <a href={item.link} className={styles.social} target="_blank">
          <img src={linkedinIcon} />
        </a>
        <div className={styles.content}>
          <p className={styles.title}>{item.name}</p>
          <p className={styles.desc}>{item.desc}</p>
        </div>
      </SwiperSlide>
    );
  });

  return (
    <>
      <SectionTitle top={234} bottom={80} mobileTop={60} mobileBottom={40}>
        Our Team
      </SectionTitle>
      <div className={`${styles.wrapper} ${styles.desktop}`}>
        <Swiper
          slidesPerView={3}
          spaceBetween={32}
          effect="slide"
          modules={[Navigation, Grid, Pagination]}
          navigation={{
            prevEl: '.' + styles.swiper_button_prev,
            nextEl: '.' + styles.swiper_button_next,
            disabledClass: styles.swiper_button_disabled,
            lockClass: styles.swiper_button_lock,
          }}
          className={styles.swiper}
          pagination={{
            el: '.' + styles.desktop_swiper_pagination,
            clickable: true,
          }}
          grid={{ rows: 2 }}
        >
          {$slides}
        </Swiper>
        <div className={`${styles.desktop_swiper_pagination}`}></div>
        <div className={`${styles.swiper_button} ${styles.swiper_button_prev}`}>
          <img src={directionLeftIcon} />
        </div>
        <div className={`${styles.swiper_button} ${styles.swiper_button_next}`}>
          <img src={directionRightIcon} />
        </div>
      </div>
      <Swiper
        slidesPerView={2}
        spaceBetween={16}
        effect="slide"
        modules={[Navigation, Grid, Pagination]}
        className={`${styles.swiper} ${styles.mobile}`}
        pagination={{
          el: '.' + styles.mobile_swiper_pagination,
          clickable: true,
        }}
        grid={{ rows: 2 }}
      >
        {$slides}
      </Swiper>
      <div className={`${styles.mobile_swiper_pagination} ${styles.mobile}`}></div>
    </>
  );
};
