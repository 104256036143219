import { SectionTitle } from '@components/section-title';
import { FC } from 'react';
import styles from './roadmap.module.scss';

export const Roadmap: FC = () => {
  const box1 = (
    <div className={styles.box}>
      <ul>
        <li>Elfin Games Launch</li>
        <li>BTC Ecosystem Integration</li>
        <li>
          Introduction of Elfin Land
          <span>&nbsp;&nbsp;&nbsp;&nbsp;Mint & Upgrade & Utility</span>
        </li>
        <li>Economics ownership and income generation</li>
        <li>Onboarding First Batch of partners in the BTC ecosystem</li>
        <li>
          Launch of First eSports games on BTC
          <span>&nbsp;&nbsp;&nbsp;&nbsp;Daily PvP & Seasonal Tournament</span>
        </li>
        <li>TGE & CEX Listing of Elfin Token</li>
      </ul>
      <p className={styles.title}>2024 Q1 & Q2</p>
      <div className={styles.line}></div>
    </div>
  );

  const box2 = (
    <div className={styles.box}>
      <ul>
        <li>More Games Launch</li>
        <li>Ongoing Seasonal Tournaments & Daily Competitions in Various Games</li>
        <li>Onboarding More Bluechip Partners and Communities in the BTC</li>
      </ul>
      <p className={styles.title}>2024 Q3</p>
      <div className={styles.line}></div>
    </div>
  );

  const box3 = (
    <div className={styles.box}>
      <ul>
        <li>Scholarship & assets renting system</li>
        <li>
          Metaverse expansion
          <span>&nbsp;&nbsp;&nbsp;&nbsp;Customization & community & DAO</span>
        </li>
        <li>Digital IP issuance & marketplace</li>
        <li>IDO & INO Launchpad</li>
        <li>Elfin Blockchain preparation & Testnet (Assets Migration Test)</li>
        <li>BTC Layer 2 or ETH Layer 3</li>
      </ul>
      <p className={styles.title}>2024 Q4</p>
      <div className={styles.line}></div>
    </div>
  );

  const box4 = (
    <div className={styles.box}>
      <ul>
        <li>Institutional & Game Studio Onboarding as Web3 Games Publishers</li>
        <li>Cross-IP & interoperable assets implementation</li>
        <li>
          Further Diversifying the Metaverse
          <span className={styles.thin}>
            &nbsp;&nbsp;&nbsp;&nbsp;▸ Economy & Interaction Across Communities & Projects
          </span>
        </li>
        <li>Building Web3 flagship eSports tournament</li>
        <li>Apple Vision Pro build of metaverse</li>
        <li>
          Mainnet launch of Elfin Blockchain
          <span className={styles.thin}>(BTC layer 2 or ETH layer 3)</span>
        </li>
      </ul>
      <p className={styles.title}>2025 & 2026</p>
      <div className={styles.line}></div>
    </div>
  );

  return (
    <>
      <SectionTitle top={170} bottom={100} mobileTop={100} mobileBottom={60}>
        Roadmap
      </SectionTitle>
      <div className={styles.desktop}>
        <div className={styles.top}>
          {box1}
          {box3}
        </div>
        <div className={styles.center}></div>
        <div className={styles.bottom}>
          {box2}
          {box4}
        </div>
      </div>
      <div className={styles.mobile}>
        <div className={styles.left}></div>
        <div className={styles.right}>
          {box1}
          {box2}
          {box3}
          {box4}
        </div>
      </div>
    </>
  );
};
