import { http } from '@libs/http';
import { defineModel } from 'foca';

export interface BalanceItem {
  id: number;
  amount: string;
  token_coin_id: number;
}

export const balanceModel = defineModel('balance', {
  initialState: <BalanceItem[]>[],
  methods: {
    async queryList() {
      const result = await http.get<BalanceItem[]>('/client/balance/list');
      this.setState(result);
    },
    async withdraw(tokenCoinId: number, amount: string) {
      await http.post('/client/balance/withdraw', {
        tokenCoinId,
        amount,
      });
    },
  },
});
