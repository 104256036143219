import { FC, useEffect } from 'react';
import { GameDetail } from './game-detail';
import styles from './all-game.module.scss';
import { useLoading, useModel } from 'foca';
import { gameModel } from '@models/game.model';
import { Loading } from '@ui/loading';
import { Empty } from '@ui/empty';

export const AllGame: FC = () => {
  const { result: games } = useModel(gameModel, (state) => state.all);
  const loading = useLoading(gameModel.queryAllList);

  useEffect(() => {
    gameModel.queryAllList('', 1, 10);
  }, []);

  return (
    <Loading spinning={loading && !games.length}>
      <div className={`${styles.wrapper} ${games.length ? '' : styles.empty_wrapper}`}>
        {!games.length && <Empty />}
        {games.map((game) => (
          <GameDetail key={game.id} gameId={game.id} />
        ))}
      </div>
    </Loading>
  );
};
