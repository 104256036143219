import { FC, MouseEvent, ReactElement, cloneElement, useState } from 'react';
import { passportModel } from '../../models/passport.model';
import { useComputed } from 'foca';
import { Dialog } from '@ui/dialog';
import styles from './index.module.scss';
import { WalletNetwork } from '@constants/wallet-network';
import { SelectNetwork } from './select-network';
import { SelectWallet } from './select-wallet';
import { WalletBase } from '@libs/wallet/wallet-base';
import { toast } from 'react-toastify';
import { useWalletAccount } from '@hooks/use-wallet';
import { walletExtensionModel } from '@models/wallet-extension.model';

interface OwnProps {
  enable?: boolean;
  children: ReactElement;
}

export const ConnectButton: FC<OwnProps> = ({ children, enable = true }) => {
  const address = useWalletAccount();
  const tokenAvailable = useComputed(passportModel.tokenAvailable, address);
  const [network, setNetwork] = useState<WalletNetwork>();
  const [dialog, setDialog] = useState(false);

  const handleClose = () => {
    setDialog(false);
    setNetwork(undefined);
  };

  const handleConnect = async (provider: WalletBase) => {
    handleClose();
    if (!network) return;

    walletExtensionModel.connecting(true);

    let address: string;
    try {
      address = await provider.connect(network);
    } catch (e) {
      toast.error('Connect failed');
      walletExtensionModel.connecting(false);
      throw e;
    }

    try {
      const nonce = await passportModel.getNonce(address);
      const signature = await provider.signMessage(nonce, address);
      const publicKey = await provider.getPublicKey();
      await passportModel.auth({
        walletAddress: address,
        network,
        signature,
        publicKey,
      });
      walletExtensionModel.update(network, provider);
    } finally {
      walletExtensionModel.connecting(false);
    }

    setTimeout(() => {
      toast.success('Wallet connected!');
    }, 50);
  };

  if (tokenAvailable || !enable) return children;

  return (
    <>
      {cloneElement(children, {
        onClick: (e: MouseEvent) => {
          e.preventDefault();
          setDialog(true);
        },
      })}
      <Dialog
        visible={dialog}
        className={styles.dialog}
        onClose={handleClose}
        title={network ? 'Connect a wallet' : 'Select a Network'}
      >
        {network ? (
          <SelectWallet
            network={network}
            onSelected={handleConnect}
            onBack={() => setNetwork(undefined)}
          />
        ) : (
          <SelectNetwork onSelected={setNetwork} />
        )}
      </Dialog>
    </>
  );
};
