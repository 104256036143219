import { EventType } from '@constants/event-type';
import { http } from '@libs/http';
import { defineModel } from 'foca';

interface RecordItem {
  id: number;
  type: EventType;
  content: string;
  created_at: string;
}

const initialState: {
  [eventType: string]: PaginationResult<RecordItem>;
} = {};

export const eventModel = defineModel('events', {
  initialState,
  methods: {
    async fetchRecords(eventType: EventType | undefined, page: number) {
      const result = await http.get<PaginationResult<RecordItem>>('/client/events', {
        params: {
          eventType,
          page,
          limit: 5,
        },
      });
      this.setState({
        [eventType || 'all']: result,
      });
    },
  },
});
