import { FC } from 'react';
import styles from './index.module.scss';
import { generateSrcset } from '@utils/generate-srcset';
import { Button } from '@ui/button';

export const ESports: FC = () => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>elfin eSports</p>
      <p className={styles.desc}>
        We have partnered with over 20 game studios, and have more than 100 games across various
        genres in the pipeline.
      </p>
      <div className={styles.games}>
        {games.map((game) => {
          const { source, srcset } = generateSrcset(game.image);

          return (
            <div key={game.title} className={styles.game}>
              <img src={source} srcSet={srcset} />
              <div>
                <p className={styles.game_name}>{game.title}</p>
                <p className={styles.game_desc}>{game.desc}</p>
                <Button
                  disabled={!game.link}
                  block
                  className={`${styles.button} ${styles.desktop}`}
                  type={game.link ? 'primary' : undefined}
                  size="large"
                >
                  {game.buttonText}
                </Button>
                <Button
                  disabled={!game.link}
                  className={`${styles.button} ${styles.mobile}`}
                  type={game.link ? 'primary' : undefined}
                  size="default"
                >
                  {game.buttonText}
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const games: {
  image: string;
  title: string;
  desc: string;
  buttonText: string;
  link: string;
}[] = [
  {
    image: 'game-1.png',
    title: 'Tap and Fly',
    desc: 'A simple yet addictive game, challenges players to navigate a bird through obstacles by tapping the screen to keep it airborne.',
    buttonText: 'Coming soon',
    link: '',
  },
  {
    image: 'game-2.png',
    title: 'Drifting',
    desc: 'Experience the thrill of drifting with just one finger! Navigate sharp turns and execute perfect drifts in this simple yet addictive tapping adventure.',
    buttonText: 'Coming soon',
    link: '',
  },
  {
    image: 'game-3.png',
    title: 'SHSHI BUMP',
    desc: "Sushi Bump is a thrilling game where you battle for dominance in sumo wrestling, all while feasting on sushi to increase your sumo's size and strength!",
    buttonText: 'Coming soon',
    link: '',
  },
  {
    image: 'game-4.png',
    title: 'Fusion Fruits',
    desc: 'Delve into the fun of Fusion Fruit Puzzle—a captivating game where you strategically merge identical fruits for delightful chain reactions and escalating excitement.',
    buttonText: 'Coming soon',
    link: '',
  },
];
