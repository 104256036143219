export const partners: {
  image: string;
  height: number;
}[] = [
  {
    image: 'partner-1.svg',
    height: 72,
  },
  {
    image: 'partner-2.svg',
    height: 45,
  },
  {
    image: 'partner-3.svg',
    height: 45,
  },
  {
    image: 'partner-4.svg',
    height: 33,
  },
  {
    image: 'partner-5.svg',
    height: 45,
  },
  {
    image: 'partner-6.svg',
    height: 45,
  },
  {
    image: 'partner-7.svg',
    height: 38,
  },
  {
    image: 'partner-8.svg',
    height: 45,
  },
  {
    image: 'partner-9.svg',
    height: 38,
  },
];
