import { FC, useState } from 'react';
import styles from './gallery.module.scss';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import arrowForwardIcon from '../../../assets/images/arrow-forward.svg';

export const Gallery: FC<{ pictures: string[] }> = ({ pictures }) => {
  const [current, setCurrent] = useState(0);

  return (
    <div className={styles.wrapper}>
      <div className={styles.picture}>
        <img src={pictures[current]} />
      </div>
      <div className={styles.swiper_wrapper}>
        <Swiper
          slidesPerView={4}
          spaceBetween={32}
          effect="slide"
          modules={[Navigation]}
          navigation={{
            prevEl: '.' + styles.swiper_button_prev,
            nextEl: '.' + styles.swiper_button_next,
          }}
          className={styles.swiper}
          loop={false}
        >
          {pictures.map((item, index) => (
            <SwiperSlide
              key={item}
              className={`${styles.swiper_item} ${current === index ? styles.swiper_active : ''}`}
              onClick={() => setCurrent(index)}
            >
              <img src={item} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={`${styles.swiper_button} ${styles.swiper_button_prev} ${styles.desktop}`}>
          <img src={arrowForwardIcon} />
        </div>
        <div className={`${styles.swiper_button} ${styles.swiper_button_next} ${styles.desktop}`}>
          <img src={arrowForwardIcon} />
        </div>
      </div>
    </div>
  );
};
