import { EventType } from '@constants/event-type';
import { eventModel } from '@models/event.model';
import Table from '@ui/table';
import dayjs from 'dayjs';
import { useLoading, useModel } from 'foca';
import { FC, useEffect } from 'react';
import styles from './event.module.scss';
import { Pagination } from '@ui/pagination';

interface OwnProps {
  eventType?: EventType;
}

export const Event: FC<OwnProps> = ({ eventType }) => {
  const { page, total, result, limit } = useModel(
    eventModel,
    (state) => state[eventType || 'all'] || { page: 1, result: [], total: 0, limit: 10 },
  );
  const loading = useLoading(eventModel.fetchRecords);

  useEffect(() => {
    eventModel.fetchRecords(eventType, 1);
  }, [eventType]);

  const handlePageChange = (page: number) => {
    eventModel.fetchRecords(eventType, page);
  };

  return (
    <>
      <Table
        loading={loading}
        head={[
          {
            title: 'Time',
          },
          {
            title: 'Type',
          },
          {
            title: 'Description',
          },
        ]}
        data={result}
        dataKey="id"
        render={(row) => {
          return (
            <tr>
              <td>{dayjs(row.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
              <td className={styles.capitalize}>{EventType[row.type]}</td>
              <td>{row.content}</td>
            </tr>
          );
        }}
      />
      <Pagination
        page={page}
        pageSize={limit}
        totalRows={total}
        onChange={handlePageChange}
        className={styles.pagination}
      />
    </>
  );
};
