import { FC } from 'react';
import styles from './footer.module.scss';
import logoIcon from '../assets/images/logo.svg';
import { Menu } from './menu';

export const Footer: FC = () => {
  return (
    <footer className={styles.wrapper}>
      <img className={styles.logo} src={logoIcon} />
      <div className={styles.row}>
        <Menu className={styles.menus} />
        <p className={styles.copyright}>Copyright © 2023 Elfin Games</p>
      </div>
    </footer>
  );
};
