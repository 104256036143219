import { WalletNetwork } from '@constants/wallet-network';
import { http } from '@libs/http';
import { defineModel } from 'foca';

interface WalletItem {
  id: number;
  wallet_address: string;
  network: WalletNetwork;
  created_at: string;
}

const initialState: WalletItem[] = [];

export const walletModel = defineModel('wallet', {
  initialState,
  methods: {
    async fetchWallets() {
      const result = await http.get<WalletItem[]>('/client/wallets');
      this.setState(result);
    },
    async addWallet(data: {
      walletAddress: string;
      signature: string;
      network: WalletNetwork;
      publicKey: string;
    }) {
      await http.post<WalletItem[]>('/client/wallets', data);
    },
    async deleteWallet(walletId: number) {
      await http.delete<WalletItem[]>(`/client/wallets/${walletId}`);
    },
  },
});
