import { type FC, type ReactNode, Fragment } from 'react';
import styles from './index.module.scss';
import { Loading } from '@ui/loading';
import { Empty } from '@ui/empty';

export interface TableColumn {
  title: string;
  /**
   * Defaults `left`
   */
  align?: 'left' | 'right' | 'center';
}

interface OwnProps<T extends object, K extends keyof T> {
  head: TableColumn[];
  loading?: boolean;
  data: T[];
  dataKey: K;
  render(row: T, index: number): ReactNode;
  className?: string;
}

const Table = <T extends object, K extends keyof T>(props: OwnProps<T, K>): ReturnType<FC> => {
  const { head, data, render, dataKey, className = '', loading } = props;

  return (
    <Loading spinning={loading && !data.length}>
      <table className={`${styles.table} ${className}`}>
        <thead>
          <tr>
            {head.map((item) => {
              const { align = 'left' } = item;
              const title = item.title;

              return (
                <th key={item.title} align={align}>
                  {title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {!data.length && (
            <tr>
              <td colSpan={100}>
                <Empty />
              </td>
            </tr>
          )}
          {data.map((row, index) => {
            return <Fragment key={String(row[dataKey])}>{render(row, index)}</Fragment>;
          })}
        </tbody>
      </table>
    </Loading>
  );
};

export default Table;
