import production, { Config } from './production';

const development: Config = {
  ...production,
  wallet_project_id: '93733c2b5aaec503a08f0d161f92449e',
  api_host: 'http://localhost:3000',
  is_mainnet: false,
  bsc_chain_id: '0x' + (97).toString(16),
};

export default development;
