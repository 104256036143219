import { config } from '@config';
import { OnekeyProvider } from './onekey-provider';

export class OnekeyBTCProvider extends OnekeyProvider {
  protected readonly provider: ONEKEY_BTC;

  constructor() {
    super();
    const provider = window.$onekey?.btc || window.unisat;
    if (!provider) throw new Error('OneKey Not installed.');
    this.provider = provider;
  }

  override async connect(): Promise<string> {
    this.provider.switchNetwork(config.is_mainnet ? 'livenet' : 'testnet');
    const addresses = await this.provider.requestAccounts();
    return addresses[0]!;
  }

  override signMessage(message: string) {
    return this.provider.signMessage(message, 'ecdsa');
  }

  override getPublicKey(): Promise<string> {
    return this.provider.getPublicKey();
  }

  override async isConnected(): Promise<boolean> {
    return this.provider._isConnected;
  }

  override async getAccount(): Promise<string | undefined> {
    return this.provider._selectedAddress || undefined;
  }

  override async getBalance(): Promise<string> {
    const balance = await this.provider.getBalance();
    return balance.confirmed.toString();
  }

  override enableTransaction(): boolean {
    return false;
  }

  override deposit(): Promise<string> {
    throw new Error('Method not implemented.');
  }
}
