import { WalletNetwork } from '@constants/wallet-network';
import { FC } from 'react';
import directionLeftIcon from '@assets/images/direction-left.svg';
import styles from './index.module.scss';
import { walletProviders } from '@libs/wallet';
import { WalletBase } from '@libs/wallet/wallet-base';

interface OwnProps {
  network: WalletNetwork;
  onBack(): void;
  onSelected(provider: WalletBase): void;
}

export const SelectWallet: FC<OwnProps> = ({ network, onBack, onSelected }) => {
  return (
    <>
      {walletProviders.map((provider) => {
        if (!provider.getSupportedChains().includes(network)) return null;
        const meta = provider.getMeta();

        return (
          <div
            className={styles.box}
            key={meta.name}
            onClick={meta.installed ? () => onSelected(provider) : undefined}
          >
            <img src={meta.icon} className={styles.logo} />
            <p>{meta.name}</p>
            <div className={styles.tag}>{meta.installed ? 'Installed' : 'Not Installed'}</div>
          </div>
        );
      })}
      <div className={styles.back}>
        <a onClick={onBack}>
          <img src={directionLeftIcon} />
          Change Network
        </a>
      </div>
    </>
  );
};
