import 'rc-tooltip/assets/bootstrap_white.css';
import { type FC, memo, ReactElement } from 'react';
import RCTooltip from 'rc-tooltip';
import styles from './not-ready-tooltip.module.scss';
import icon from '@assets/images/nav-tooltip-bg.svg';

const NotReadyToolTip: FC<{ children: ReactElement }> = ({ children }) => {
  const overlay = (
    <>
      <p>Coming Soon ...</p>
      <img src={icon} className={styles.icon} />
    </>
  );

  return (
    <RCTooltip
      placement="bottom"
      destroyTooltipOnHide
      showArrow
      overlayClassName={styles.tooltip}
      overlay={overlay}
    >
      {children}
    </RCTooltip>
  );
};

export default memo(NotReadyToolTip);
